const initialState = {
  languages: [
    { id: 1, code: 'ru', title: 'Русский' },
    { id: 2, code: 'uz', title: "O'zbekcha" },
    { id: 3, code: 'en', title: 'English' },
  ],
  defaultLanguage: 'ru',
};

export const LanguagesReducer = (state = initialState) => {
  return state;
};
