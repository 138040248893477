import {
  CREATE_CELL,
  DELETE_CELL,
  FETCHING_CELLS,
  FETCH_CELLS,
  PUSH_CELLS,
  UPDATE_CELL,
} from './cell.actions';

const initialState = {
  cells: [],
  cellsLength: 0,
  loading: false,
  queryParams: {},
};

export const CellsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCHING_CELLS:
      return {
        ...state,
        loading: payload,
      };
    case FETCH_CELLS:
      return {
        ...state,
        cells: payload.data,
        cellsLength: payload.dataLength,
      };
    case PUSH_CELLS:
      return {
        ...state,
        cells: [...state.cells, ...payload.data],
        cellsLength: payload.dataLength,
      };
    case CREATE_CELL:
      return {
        ...state,
        cells: [payload, ...state.cells],
        cellsLength: state.cellsLength + 1,
      };
    case UPDATE_CELL:
      return {
        ...state,
        cells: state.cells.map(cell =>
          cell.id === payload.id ? payload : cell,
        ),
      };
    case DELETE_CELL:
      return {
        ...state,
        cells: state.cells.filter(cell => cell.id !== payload),
        cellsLength: state.cellsLength - 1,
      };
    default:
      return state;
  }
};
