import { CHANGE_PAGING, CHANGE_SIDEBAR } from './site.actions';

// Function to load state from local storage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('siteSettings');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error('Error loading state from local storage:', err);
    return undefined;
  }
};

// Function to save state to local storage
const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('siteSettings', serializedState);
  } catch (err) {
    console.error('Error saving state to local storage:', err);
  }
};

const initialState = loadState() || {
  pagingState: false,
  CollapseDataSidebar: false,
};

export const SiteSettingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_PAGING:
      const newState = {
        ...state,
        pagingState: !state.pagingState,
      };
      saveState(newState);
      return newState;

    case CHANGE_SIDEBAR:
      const updatedState = {
        ...state,
        CollapseDataSidebar: payload,
      };
      saveState(updatedState);
      return updatedState;

    default:
      return state;
  }
};

// import { CHANGE_PAGING, CHANGE_SIDEBAR } from './site.actions';

// const initialState = {
//   pagingState: false,
//   CollapseDataSidebar: false,
// };

// export const SiteSettingReducer = (state = initialState, { type, payload }) => {
//   switch (type) {
//     case CHANGE_PAGING:
//       return {
//         ...state,
//         pagingState: !state.pagingState,
//       };
//     case CHANGE_SIDEBAR:
//       return {
//         ...state,
//         CollapseDataSidebar: payload,
//       };
//     default:
//       return state;
//   }
// };
