import { http } from './api.service';

export const PageService = {
  getPages(params) {
    return http.get('/pages', {
      params,
    });
  },
  createPage(data) {
    return http.post('/pages', data);
  },
  updatePage(data) {
    return http.post(`/pages/${data.get('id')}`, data);
  },
  deletePage(id) {
    return http.delete(`/pages/${id}`);
  },
  deletePages(ids) {
    return http.delete(`/pages/${ids.join(',')}`);
  },
};
