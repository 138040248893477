import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CustomErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import NavbarMain from '../../components/Navbar';
import { Loader } from '../../components/Loader/Loader';
import { fetchMe } from '../../redux/auth/authAction';
import '../Main/Main.scss';
import '../../components/SidebarSettings.scss';
import {
  fetchPhonePrefixes,
  setError,
} from '../../redux/common/common.actions';

const mapStateToProps = state => ({
  loading: state.common.loading,
  user: state.auth.user,
  error: state.common.error,
});

export const MainLayout = connect(mapStateToProps, {
  fetchMe,
  setError,
  fetchPhonePrefixes,
})(
  ({
    children,
    loading,
    user,
    fetchMe,
    setError,
    error,
    fetchPhonePrefixes,
  }) => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
      if (!user) {
        fetchMe()
          .then(() => {
            fetchPhonePrefixes();
          })
          .catch(err => {
            if (err.code === 'ECONNABORTED') {
              setError({ offline: true });
            } else if (err.response) {
              // setError(err.response);
            }
          })
          .finally(() => setLoader(false));
      } else {
        setLoader(false);
      }
    }, []);

    if (loader) {
      return <Loader />;
    }

    return (
      <>
        {!error?.offline && <NavbarMain />}
        {loading && <Loader />}
        <CustomErrorBoundary>{children}</CustomErrorBoundary>
      </>
    );
  },
);
