import {
  CREATE_PLACE,
  UPDATE_PLACE,
  DELETE_PLACE,
  FETCH_BRANCHES_COUNTRIES,
  FETCH_COUNTRIES,
  FETCH_REGIONS,
  FETCH_DISTRICTS,
  FETCH_STREETS,
  SET_COUNTRY,
  SET_REGION,
  SET_DISTRICT,
  SET_STREET,
  REQUEST_PLACES,
  RESET_PLACES,
  FETCH_UZ_DISTRICTS,
  SET_UZ_DISTRICTS_LOADER,
} from './country.actions';
import { formattedData } from '../../utils/format-unit';

const initState = {
  countries: {
    base: {
      data: [],
      loading: false,
      id: null,
    },
    sender: {
      data: [],
      loading: false,
      id: null,
    },
    receiver: {
      data: [],
      loading: false,
      id: null,
    },
    place: {
      data: [],
      loading: false,
      id: null,
    },
  },
  regions: {
    base: {
      data: [],
      loading: false,
      id: null,
      district_id: null,
    },
    sender: {
      data: [],
      loading: false,
      id: null,
      district_id: null,
    },
    receiver: {
      data: [],
      loading: false,
      id: null,
      district_id: null,
    },
    place: {
      data: [],
      loading: false,
      id: null,
      district_id: null,
    },
  },
  districts: {
    base: {
      data: [],
      loading: false,
      id: null,
    },
    sender: {
      data: [],
      loading: false,
      id: null,
    },
    receiver: {
      data: [],
      loading: false,
      id: null,
    },
    place: {
      data: [],
      loading: false,
      id: null,
    },
  },
  streets: {
    base: {
      data: [],
      loading: false,
      id: null,
    },
    sender: {
      data: [],
      loading: false,
      id: null,
    },
    receiver: {
      data: [],
      loading: false,
      id: null,
    },
    place: {
      data: [],
      loading: false,
      id: null,
    },
  },
  branchCountries: [],
  uz_districts: [],
  loading: false,
};

export const CountryReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_UZ_DISTRICTS_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case REQUEST_PLACES:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          [payload.prefix]: {
            ...state[payload.type][payload.prefix],
            loading: payload.data,
          },
        },
      };
    case FETCH_COUNTRIES:
      return {
        ...state,
        countries: {
          ...state.countries,
          [payload.prefix]: {
            ...state.countries[payload.prefix],
            data: payload.resetData
              ? payload.data
              : [...state.countries[payload.prefix].data, ...payload.data],
          },
        },
      };
    case FETCH_REGIONS:
      return {
        ...state,
        regions: {
          ...state.regions,
          [payload.prefix]: {
            ...state.regions[payload.prefix],
            data: payload.resetData
              ? payload.data
              : [...state.regions[payload.prefix].data, ...payload.data],
          },
        },
      };
    case FETCH_DISTRICTS:
      return {
        ...state,
        districts: {
          ...state.districts,
          [payload.prefix]: {
            ...state.districts[payload.prefix],
            data: payload.resetData
              ? payload.data
              : [...state.districts[payload.prefix].data, ...payload.data],
          },
        },
      };
    case FETCH_STREETS:
      return {
        ...state,
        streets: {
          ...state.streets,
          [payload.prefix]: {
            ...state.streets[payload.prefix],
            data: payload.resetData
              ? payload.data
              : [...state.streets, ...payload.data],
          },
        },
      };
    case RESET_PLACES:
      return {
        ...state,
        countries: {
          ...state.countries,
          [payload]: {
            ...state.countries[payload],
            id: initState.countries[payload].id,
            loading: initState.countries[payload].loading,
          },
        },
        regions: {
          ...state.regions,
          [payload]: initState.regions[payload],
        },
        districts: {
          ...state.districts,
          [payload]: initState.districts[payload],
        },
        streets: {
          ...state.streets,
          [payload]: initState.streets[payload],
        },
      };
    case SET_COUNTRY:
      return {
        ...state,
        countries: {
          ...state.countries,
          [payload.prefix]: {
            ...state.countries[payload.prefix],
            id: payload.data,
          },
        },
      };
    case SET_REGION:
      return {
        ...state,
        regions: {
          ...state.regions,
          [payload.prefix]: {
            ...state.regions[payload.prefix],
            id: payload.data,
            district_id: payload.district_id,
          },
        },
      };
    case SET_DISTRICT:
      return {
        ...state,
        districts: {
          ...state.districts,
          [payload.prefix]: {
            ...state.districts[payload.prefix],
            id: payload.data,
          },
        },
      };
    case SET_STREET:
      return {
        ...state,
        streets: {
          ...state.streets,
          [payload.prefix]: {
            ...state.streets[payload.prefix],
            id: payload.data,
          },
        },
      };
    case CREATE_PLACE:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          place: {
            ...state[payload.type].place.data,
            data: [payload.data, ...state[payload.type].place.data],
          },
          base: {
            ...state[payload.type].base.data,
            data: [payload.data, ...state[payload.type].base.data],
          },
        },
      };
    case UPDATE_PLACE:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          place: {
            ...state[payload.type].place.data,
            data: state[payload.type].place.data.map(item =>
              item.id === payload.data.id ? payload.data : item,
            ),
          },
          base: {
            ...state[payload.type].base.data,
            data: state[payload.type].base.data.map(item =>
              item.id === payload.data.id ? payload.data : item,
            ),
          },
        },
      };
    case DELETE_PLACE:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          place: {
            ...state[payload.type].place.data,
            data: state[payload.type].place.data.filter(
              item => item.id !== payload.data.id,
            ),
          },
          base: {
            ...state[payload.type].base.data,
            data: state[payload.type].base.data.filter(
              item => item.id !== payload.data.id,
            ),
          },
        },
      };
    case FETCH_BRANCHES_COUNTRIES:
      return {
        ...state,
        branchCountries: payload,
      };
    case FETCH_UZ_DISTRICTS:
      const formatData = formattedData(payload.data);
      return {
        ...state,
        uz_districts: formatData,
      };
    default:
      return state;
  }
};
