import {
  CREATE_SHOP,
  FETCHING_SHOPS,
  FETCH_SHOPS,
  PUSH_SHOPS,
  DELETE_SHOP,
  UPDATE_SHOP,
} from './shop.actions';

const initialState = {
  shops: [],
  shopsLength: 0,
  loading: false,
  types: [],
};

export const ShopsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCHING_SHOPS:
      return {
        ...state,
        loading: payload,
      };
    case FETCH_SHOPS:
      return {
        ...state,
        shops: payload.data,
        shopsLength: payload.dataLength,
      };
    case PUSH_SHOPS:
      return {
        ...state,
        shops: [...state.shops, ...payload.data],
        shopsLength: payload.dataLength,
      };
    case CREATE_SHOP:
      return {
        ...state,
        shops: [payload, ...state.shops],
        shopsLength: state.shopsLength + 1,
      };
    case UPDATE_SHOP:
      return {
        ...state,
        shops: state.shops.map((shop) =>
          shop.id === payload.id ? payload : shop,
        ),
      };
    case DELETE_SHOP:
      return {
        ...state,
        shops: state.shops.filter((shop) => shop.id !== payload),
        shopsLength: state.shopsLength - 1,
      };
    default:
      return state;
  }
};
