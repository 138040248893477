export const ACCESS_TOKEN = 'token';
export const BRANCH_ID = 'branch';

export const StorageService = {
  getToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  },
  setToken(token) {
    localStorage.setItem(ACCESS_TOKEN, token);
  },
  getBranch() {
    return localStorage.getItem(BRANCH_ID);
  },
  setBranch(value) {
    return localStorage.setItem(BRANCH_ID, value);
  },
  logout() {
    localStorage.clear();
  },
};
