const { http } = require('./api.service');

export const AuthService = {
  login(user) {
    return http.post('/auth/login', user);
  },
  getSettings() {
    return http.get('/setting');
  },
  updateSite(data) {
    return http.put(`/setting/1`, data);
  },
  getMe() {
    return http.get('/me');
  },
  setUserBranch(data) {
    return http.get('/branches/set-default/' + data.id);
  },
};
