import { http } from './api.service';

export const CountryService = {
  getCountries(params) {
    return http.get('/countries', {
      params,
    });
  },
  getRegions(params) {
    return http.get('/regions', {
      params,
    });
  },
  getDistricts(params) {
    return http.get('/districts', {
      params,
    });
  },
  getUzDistricts(params) {
    return http.get(`/districts-uz${params ?? ''}`);
  },
  getStreets(params) {
    return http.get('/streets', { params });
  },
  getZipCodes(data, params) {
    return http.get('/zipcode/' + data.zip_code, { params });
  },
  createCountry(country, params) {
    return http.post('/countries', country, {
      params,
    });
  },
  updateCountry(country, params) {
    return http.put(`/countries/${country.id}`, country, {
      params,
    });
  },
  deleteCountry(country) {
    return http.delete(`/countries/${country.id}`);
  },
  createRegion(region, params) {
    return http.post('/regions', region, {
      params,
    });
  },
  updateRegion(region, params) {
    return http.put(`/regions/${region.id}`, region, {
      params,
    });
  },
  deleteRegion(region) {
    return http.delete(`/regions/${region.id}`);
  },
  createDistrict(district, params) {
    return http.post('/districts', district, {
      params,
    });
  },
  updateDistrict(district, params) {
    return http.put(`/districts/${district.id}`, district, {
      params,
    });
  },
  deleteDistrict(district) {
    return http.delete(`/districts/${district.id}`);
  },
  createStreet(street, params) {
    return http.post('/streets', street, {
      params,
    });
  },
  updateStreet(street, params) {
    return http.put(`/streets/${street.id}`, street, {
      params,
    });
  },
  deleteStreet(street) {
    return http.delete(`/streets/${street.id}`);
  },
  getBranchCountries(params) {
    return http.get('/countries/has-branch', {
      params,
    });
  },
};
