import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  SELECTED_CATEGORY,
  DELETE_CATEGORIES,
  FETCHING_CATEGORIES,
  FETCH_CATEGORIES,
  FETCH_CATEGORY_PARENTS,
  FETCH_CATEGORY_TYPES,
  PUSH_CATEGORIES,
  UPDATE_CATEGORY,
  SET_CATEGORY_QUERY_PARAM,
} from './categories.actions';

const initialState = {
  categories: [],
  selectedCategories: [],
  categoriesLength: 0,
  loading: false,
  types: [],
  parents: [],
  queryParams: {},
};

export const CategoriesReducer = (state = initialState, { type, payload }) => {
  const isEmpty = payload && !Object.keys(payload).length;
  switch (type) {
    case SET_CATEGORY_QUERY_PARAM:
      return {
        ...state,
        queryParams: isEmpty
          ? {}
          : {
              ...state.queryParams,
              ...payload,
            },
      };
    case FETCHING_CATEGORIES:
      return {
        ...state,
        loading: payload,
      };
    case FETCH_CATEGORIES:
      return {
        ...state,
        categories: payload.data,
        categoriesLength: payload.dataLength,
      };
    case PUSH_CATEGORIES:
      return {
        ...state,
        categories: [...state.categories, ...payload.data],
        categoriesLength: payload.dataLength,
      };
    case CREATE_CATEGORY:
      return {
        ...state,
        categories: [payload, ...state.categories],
        parents: [payload, ...state.parents],
        categoriesLength: state.categoriesLength + 1,
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        categories: state.categories.map(category =>
          category.id === payload.id ? payload : category,
        ),
      };
    case SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategories: [payload, ...state.selectedCategories],
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(
          category => category.id !== payload,
        ),
        categoriesLength: state.categoriesLength - 1,
      };
    case DELETE_CATEGORIES:
      return {
        ...state,
        categories: state.categories.filter(
          category => !payload.includes(category.id),
        ),
        categoriesLength: state.categoriesLength - payload.length,
      };
    case FETCH_CATEGORY_TYPES:
      return {
        ...state,
        types: payload,
      };
    case FETCH_CATEGORY_PARENTS:
      return {
        ...state,
        parents: payload,
      };
    default:
      return state;
  }
};
