import {
  FETCH_ORDERS,
  FETCH_ORDERS_PAGINATION,
  FETCH_ORDER_FILTERS,
  FETCH_ORDER_INFO,
  SET_ORDER_QUERY_PARAM,
  DELETE_ORDERS,
  CREATE_ORDER,
  UPDATE_ORDER,
  FETCH_ORDERS_OBJECT,
  SET_ORDER_MODAL_DATA,
  FETCH_AVAILABLE_STATUSES,
  UPDATE_ORDERS_LIST,
  FETCH_ORDER,
  FETCH_APPLICATIONS,
  FETCH_APPLICATION,
  SET_APPLICATION_MODAL_DATA,
  CREATE_PICKUP,
  CREATE_DELIVERY_SERVICE,
  CREATE_HELP_TO_SHOP,
  SET_APPLICATION_QUERY_PARAM,
  SET_APPLICATION_STATUSES,
  SET_SELECTED_ROWS,
} from './orders.actions';

const initialState = {
  selectedRows: [],
  orders: [],
  applications: [],
  ordersObject: {},
  order: null,
  application: null,
  filters: [],
  info: [],
  queryParams: {},
  appQueryParams: {
    'filter[receive_type]': 'all',
  },
  appStatuses: [],
  ordersLength: 0,
  isObjectData: true,
  availableStatuses: [],
  applicationModals: [
    { items: [{ category: null }] },
    { items: [{ category: null }] },
    { items: [{ category: null }] },
    { items: [{ category: null }] },
    { items: [{ category: null }] },
    { items: [{ category: null }] },
  ],
  orderModals: [
    {
      packages: [{ package: null, client: null, qty: null }],
      items: [{ category: null }],
      totalItems: 0,
      totalPackages: 0,
      totalItemsWeight: 0,
      totalPackagesWeight: 0,
      totalOffers: 0,
      totalFee: 0,
      totalItemsPrice: 0,
      totalOrderPrice: 0,
      totalPackagesPrice: 0,
      totalPackagesFeePrice: 0,
      discount: 0,
      actualWeight: 0,
      feeName: '',
    },
  ],
};

const mergeObject = (prev, next) => {
  const result = { ...prev };
  Object.keys(next).forEach(key => {
    if (result[key]) {
      result[key] = [...result[key], ...next[key]];
    } else {
      result[key] = next[key];
    }
  });
  return result;
};

export const OrderReducer = (state = initialState, { type, payload }) => {
  const isEmpty = payload && !Object.keys(payload).length;
  switch (type) {
    case SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: payload,
      };
    case FETCH_ORDERS:
      return {
        ...state,
        orders: payload.resetData
          ? payload.data
          : [...state.orders, ...payload.data],
        ordersLength: payload.dataLength,
        isObjectData: false,
      };

    case FETCH_ORDERS_PAGINATION:
      const orders1 = [];
      const ordersObject1 = payload.resetData
        ? payload.data
        : mergeObject([], payload.data);

      Object.keys(ordersObject1).forEach(key => {
        orders1.push(
          {
            key: Math.floor(Math.random() * 100000),
            date: key,
          },
          ...ordersObject1[key],
        );
      });

      return {
        ...state,
        orders: orders1,
        ordersLength: payload.dataLength,
        isObjectData: false,
      };

    case FETCH_APPLICATIONS:
      return {
        ...state,
        applications: payload.resetData
          ? payload.data
          : [...state.applications, ...payload.data],
      };
    case FETCH_APPLICATION:
      return {
        ...state,
        application: payload,
      };
    case FETCH_ORDER:
      return {
        ...state,
        order: payload,
      };
    case FETCH_ORDERS_OBJECT:
      const orders = [];
      const ordersObject = payload.resetData
        ? payload.data
        : mergeObject(state.ordersObject, payload.data);

      Object.keys(ordersObject).forEach(key => {
        orders.push(
          {
            key: Math.floor(Math.random() * 100000),
            date: key,
          },
          ...ordersObject[key],
        );
      });

      return {
        ...state,
        orders,
        ordersObject,
        ordersLength: payload.dataLength,
        isObjectData: true,
      };
    case FETCH_ORDER_FILTERS:
      return { ...state, filters: payload };
    case FETCH_ORDER_INFO:
      return { ...state, info: payload };
    case FETCH_AVAILABLE_STATUSES:
      return { ...state, availableStatuses: payload };
    case SET_ORDER_QUERY_PARAM:
      return {
        ...state,
        queryParams: isEmpty
          ? {}
          : {
              ...state.queryParams,
              ...payload,
            },
      };
    case SET_APPLICATION_STATUSES:
      return {
        ...state,
        appStatuses: payload,
      };
    case SET_APPLICATION_QUERY_PARAM:
      return {
        ...state,
        appQueryParams: isEmpty
          ? initialState.appQueryParams
          : {
              ...state.appQueryParams,
              ...payload,
            },
      };
    case SET_ORDER_MODAL_DATA:
      return {
        ...state,
        orderModals: isEmpty
          ? initialState.orderModals
          : state.orderModals.map((modal, i) => {
              if (i === payload.type) {
                return {
                  ...modal,
                  ...payload.data,
                };
              }
              return modal;
            }),
      };
    case SET_APPLICATION_MODAL_DATA:
      return {
        ...state,
        applicationModals: isEmpty
          ? initialState.applicationModals
          : state.applicationModals.map((modal, i) => {
              if (i === payload.type) {
                return {
                  ...modal,
                  ...payload.data,
                };
              }
              return modal;
            }),
      };
    case CREATE_PICKUP:
      return {
        ...state,
        applications: [payload, ...state.applications],
      };

    case CREATE_DELIVERY_SERVICE:
      return {
        ...state,
        applications: [payload, ...state.applications],
      };
    case CREATE_HELP_TO_SHOP:
      return {
        ...state,
        applications: [payload, ...state.applications],
      };
    case CREATE_ORDER:
      return {
        ...state,
        orders: [payload, ...state.orders],
        ordersLength: state.ordersLength + 1,
      };
    case UPDATE_ORDER:
      return {
        ...state,
        orders: state.orders.map(order =>
          order.id === payload.id ? payload : order,
        ),
      };
    case UPDATE_ORDERS_LIST:
      return {
        ...state,
        orders: state.orders.map(order => {
          const hasItem = payload.find(item => item.id === order.id);
          if (hasItem) {
            return hasItem;
          }
          return order;
        }),
      };
    case DELETE_ORDERS:
      return {
        ...state,
        orders: state.orders.filter(order => !payload.includes(order.id)),
        ordersLength: state.ordersLength - 1,
      };
    default:
      return state;
  }
};
