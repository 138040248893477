import { OPERATOR_STATUS_DELETED } from '../../constants/operators';
import {
  CREATE_OPERATOR,
  DELETE_OPERATOR,
  FETCH_OPERATORS,
  PUSH_OPERATORS,
  UPDATE_OPERATOR,
  SET_OPERATORS_LOADER,
} from './operators.actions';

const initialState = {
  operators: [],
  operatorsLength: 0,
  loading: false,
};

export const OperatorsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_OPERATORS_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case FETCH_OPERATORS:
      return {
        ...state,
        operators: payload.data,
        operatorsLength: payload.dataLength,
      };
    case PUSH_OPERATORS:
      return {
        ...state,
        operators: [...state.operators, ...payload.data],
        operatorsLength: payload.dataLength,
      };
    case CREATE_OPERATOR:
      return {
        ...state,
        operators: [payload, ...state.operators],
        operatorsLength: state.operatorsLength + 1,
      };
    case UPDATE_OPERATOR:
      return {
        ...state,
        operators: state.operators.map((operator) =>
          operator.id === payload.id ? payload : operator,
        ),
      };
    case DELETE_OPERATOR:
      return {
        ...state,
        operators: state.operators.map((operator) => {
          if (operator.id === payload) {
            return {
              ...operator,
              status: OPERATOR_STATUS_DELETED,
            };
          }
          return operator;
        }),
        operatorsLength: state.operatorsLength - 1,
      };
    default:
      return state;
  }
};
