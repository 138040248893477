export const objectToQueryString = (obj) => {
  return Object.keys(obj).reduce(function (str, key, i) {
    var delimiter, val;
    delimiter = i === 0 ? '?' : '&';
    val = obj[key];
    return [str, delimiter, key, '=', val].join('');
  }, '');
};

export const stringQueryToObject = (querystring) => {
  const params = new URLSearchParams(querystring);

  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};
