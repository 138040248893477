import {
  FETCH_INVOICES,
  FETCH_INVOICES_FILTERS,
  FETCH_INVOICES_INFO,
  FETCH_PAYMENT_METHODS,
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTION_FILTERS,
  FETCH_TRANSACTION_INFO,
  SET_TRANSACTION_QUERY_PARAM,
  SET_INVOICES_QUERY_PARAM,
  FETCH_CASHRECEIPT,
  FETCH_CASHRECEIPT_INFO,
  FETCH_CASHRECEIPT_FILTERS,
  SET_CASHRECEIPT_QUERY_PARAM,
} from './payment.actions';

const initialState = {
  methods: [],
  invoices: [],
  transactions: [],
  cashReceipt: [],
  transactionsFilters: [],
  transactionsInfo: [],
  transactionsQueryParams: {},
  invoicesFilters: [],
  invoicesInfo: [],
  invoicesQueryParams: {},
  cashReceiptFilters: [],
  cashReceiptInfo: [],
  cashReceiptQueryParams: {},
};

export const PaymentReducer = (state = initialState, { type, payload }) => {
  const isEmpty = payload && !Object.keys(payload).length;
  switch (type) {
    case FETCH_PAYMENT_METHODS:
      return {
        ...state,
        methods: payload,
      };
    case FETCH_TRANSACTIONS:
      return {
        ...state,
        transactions: payload.resetData
          ? payload.data
          : [...state.transactions, ...payload.data],
      };
    case FETCH_TRANSACTION_INFO:
      return {
        ...state,
        transactionsInfo: payload,
      };
    case FETCH_TRANSACTION_FILTERS:
      return {
        ...state,
        transactionsFilters: payload,
      };
    case FETCH_INVOICES:
      return {
        ...state,
        invoices: payload.resetData
          ? payload.data
          : [...state.invoices, ...payload.data],
      };
    case FETCH_INVOICES_INFO:
      return {
        ...state,
        invoicesInfo: payload,
      };
    case FETCH_INVOICES_FILTERS:
      return {
        ...state,
        invoicesFilters: payload,
      };
    case FETCH_CASHRECEIPT:
      return {
        ...state,
        cashReceipt: payload.resetData
          ? payload.data
          : [...state.cashReceipt, ...payload.data],
      };
    case FETCH_CASHRECEIPT_INFO:
      return {
        ...state,
        cashReceiptInfo: payload,
      };
    case FETCH_CASHRECEIPT_FILTERS:
      return {
        ...state,
        cashReceiptFilters: payload,
      };
    case SET_TRANSACTION_QUERY_PARAM:
      return {
        ...state,
        transactionsQueryParams: isEmpty
          ? {}
          : {
              ...state.transactionsQueryParams,
              ...payload,
            },
      };
    case SET_INVOICES_QUERY_PARAM:
      return {
        ...state,
        invoicesQueryParams: isEmpty
          ? {}
          : {
              ...state.invoicesQueryParams,
              ...payload,
            },
      };
    case SET_CASHRECEIPT_QUERY_PARAM:
      return {
        ...state,
        cashReceiptQueryParams: isEmpty
          ? {}
          : {
              ...state.cashReceiptQueryParams,
              ...payload,
            },
      };
    default:
      return state;
  }
};
