import axios from 'axios';
import { http } from './api.service';

export const PaymentService = {
  getPaymentMethods(params) {
    return http.get('/payment-types', {
      params,
    });
  },
  getTransactions(id, params) {
    return axios.get('https://turonexpress.com/api/transactions/' + id, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },
  fetchTransactions(params) {
    return http.get('/finance/transactions', { params });
  },
  fetchInvoices(params) {
    return http.get('/finance/invoices', { params });
  },
  fetchCashReceipt(params) {
    return http.get('/finance/cash-receipts', { params });
  },
  fetchTransactionFilters() {
    return http.get('/finance/transactions/filter-list');
  },
  fetchCashReceiptInfo(id) {
    return http.get('/finance/cash-receipts/' + id);
  },
  fetchCashReceiptTransactions(id) {
    return http.get(`/finance/cash-receipts/${id}/transactions/`);
  },

  fetchInvoiceFilters() {
    return http.get('/finance/invoices/filter-list');
  },
  fetchCashReceiptFilters() {
    return http.get('/finance/cash-receipts/filter-list');
  },
  fetchCashReceiptAccept(id) {
    return http.post(`/finance/cash-receipts/${id}/accept/`);
  },
  createPayment(data) {
    return http.post(`/${data.type}/${data.model}/${data.id}`, data);
  },
  cancelPayment(data) {
    return http.put('/payment/cancel/' + data?.id);
  },
};
