import { http } from './api.service';

export const DeliveryService = {
  getDeliverys(params) {
    return http.get('/shipments/?filter[user_id]=23&filter[completed]=0', {
      params,
    });
  },
  getDeliveryFilters() {
    return http.get('/shipments/filter-list');
  },

  getDeliveryOrder(id) {
    return http.get(`/shipments/${id}`);
  },
};
