import { CountryService } from '../../services/country.service';

export const REQUEST_PLACES = 'REQUEST_PLACES';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_REGIONS = 'FETCH_REGIONS';
export const FETCH_DISTRICTS = 'FETCH_DISTRICTS';
export const FETCH_UZ_DISTRICTS = 'FETCH_UZ_DISTRICTS';
export const FETCH_STREETS = 'FETCH_STREETS';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_REGION = 'SET_REGION';
export const SET_DISTRICT = 'SET_DISTRICT';
export const SET_STREET = 'SET_STREET';
export const CREATE_PLACE = 'CREATE_PLACE';
export const UPDATE_PLACE = 'UPDATE_PLACE';
export const DELETE_PLACE = 'DELETE_PLACE';
export const RESET_PLACES = 'RESET_PLACES';

export const FETCH_BRANCHES_COUNTRIES = 'FETCH_BRANCHES_COUNTRIES';
export const SET_UZ_DISTRICTS_LOADER = 'SET_UZ_DISTRICTS_LOADER';

export const changeCountry = (prefix, value) => dispatch => {
  dispatch({ type: SET_COUNTRY, payload: { data: value, prefix } });
  dispatch({ type: SET_REGION, payload: { data: null, prefix } });
  dispatch({ type: SET_DISTRICT, payload: { data: null, prefix } });
  dispatch({ type: SET_STREET, payload: { data: null, prefix } });
  dispatch({
    type: FETCH_REGIONS,
    payload: { data: [], prefix, resetData: true },
  });
  dispatch({
    type: FETCH_DISTRICTS,
    payload: { data: [], prefix, resetData: true },
  });
  dispatch({
    type: FETCH_STREETS,
    payload: { data: [], prefix, resetData: true },
  });
};

export const changeRegion = (prefix, value) => dispatch => {
  dispatch({ type: SET_REGION, payload: { data: value, prefix } });
  dispatch({ type: SET_DISTRICT, payload: { data: null, prefix } });
  dispatch({ type: SET_STREET, payload: { data: null, prefix } });
  dispatch({
    type: FETCH_DISTRICTS,
    payload: { data: [], prefix, resetData: true },
  });
  dispatch({
    type: FETCH_STREETS,
    payload: { data: [], prefix, resetData: true },
  });
};

export const changeDistrict = (prefix, value) => dispatch => {
  dispatch({ type: SET_DISTRICT, payload: { data: value, prefix } });
  dispatch({ type: SET_STREET, payload: { data: null, prefix } });
  dispatch({
    type: FETCH_STREETS,
    payload: { data: [], prefix, resetData: true },
  });
};

export const fetchCountries = (
  params,
  prefix,
  resetData = true,
) => async dispatch => {
  dispatch({
    type: REQUEST_PLACES,
    payload: { prefix, type: 'countries', data: true },
  });
  const res = await CountryService.getCountries(params);
  dispatch({
    type: FETCH_COUNTRIES,
    payload: { ...res.data, resetData, prefix },
  });

  dispatch({
    type: REQUEST_PLACES,
    payload: { prefix, type: 'countries', data: false },
  });
  return res.data;
};

export const fetchRegions = (
  params,
  prefix,
  resetData = true,
) => async dispatch => {
  dispatch({
    type: REQUEST_PLACES,
    payload: { prefix, type: 'regions', data: true },
  });
  const res = await CountryService.getRegions(params);
  dispatch({
    type: FETCH_REGIONS,
    payload: { ...res.data, prefix, resetData },
  });

  dispatch({
    type: REQUEST_PLACES,
    payload: { prefix, type: 'regions', data: false },
  });
  return res.data;
};

export const fetchDistricts = (
  params,
  prefix,
  resetData = true,
) => async dispatch => {
  dispatch({
    type: REQUEST_PLACES,
    payload: { prefix, type: 'districts', data: true },
  });
  const res = await CountryService.getDistricts(params);
  dispatch({
    type: FETCH_DISTRICTS,
    payload: { ...res.data, prefix, resetData },
  });

  dispatch({
    type: REQUEST_PLACES,
    payload: { prefix, type: 'districts', data: false },
  });
  return res.data;
};

export const fetchUzDistricts = (
  params,
  prefix,
  resetData = true,
) => async dispatch => {
  dispatch({ type: SET_UZ_DISTRICTS_LOADER, payload: true });
  dispatch({
    type: REQUEST_PLACES,
    payload: { prefix, type: 'districts', data: true },
  });
  const res = await CountryService.getUzDistricts(params);
  dispatch({
    type: FETCH_UZ_DISTRICTS,
    payload: { ...res.data, prefix, resetData },
  });

  dispatch({
    type: REQUEST_PLACES,
    payload: { prefix, type: 'districts', data: false },
  });
  dispatch({ type: SET_UZ_DISTRICTS_LOADER, payload: false });
  return res.data;
};

export const fetchStreets = (
  params,
  prefix,
  resetData = true,
) => async dispatch => {
  dispatch({
    type: REQUEST_PLACES,
    payload: { prefix, type: 'streets', data: true },
  });
  const res = await CountryService.getStreets(params);
  dispatch({
    type: FETCH_STREETS,
    payload: { ...res.data, prefix, resetData },
  });

  dispatch({
    type: REQUEST_PLACES,
    payload: { prefix, type: 'streets', data: false },
  });
  return res.data;
};

export const createCountry = country => async dispatch => {
  const res = await CountryService.createCountry(country);
  dispatch({
    type: CREATE_PLACE,
    payload: { data: res.data.data, type: 'countries' },
  });
};

export const updateCountry = country => async dispatch => {
  const res = await CountryService.updateCountry(country);
  dispatch({
    type: UPDATE_PLACE,
    payload: { data: res.data.data, type: 'countries' },
  });
};

export const deleteCountry = countryId => async dispatch => {
  await CountryService.deleteCountry(countryId);
  dispatch({
    type: DELETE_PLACE,
    payload: { data: countryId, type: 'countries' },
  });
};

export const createRegion = region => async dispatch => {
  const res = await CountryService.createRegion(region);
  dispatch({
    type: CREATE_PLACE,
    payload: { data: res.data.data, type: 'regions' },
  });
};

export const updateRegion = region => async dispatch => {
  const res = await CountryService.updateRegion(region);
  dispatch({
    type: UPDATE_PLACE,
    payload: { data: res.data.data, type: 'regions' },
  });
};

export const deleteRegion = regionId => async dispatch => {
  await CountryService.deleteRegion(regionId);
  dispatch({
    type: DELETE_PLACE,
    payload: { data: regionId, type: 'regions' },
  });
};

export const createDistrict = district => async dispatch => {
  const res = await CountryService.createDistrict(district);
  dispatch({
    type: CREATE_PLACE,
    payload: { data: res.data.data, type: 'districts' },
  });
};

export const updateDistrict = district => async dispatch => {
  const res = await CountryService.updateDistrict(district);
  dispatch({
    type: UPDATE_PLACE,
    payload: { data: res.data.data, type: 'districts' },
  });
};

export const deleteDistrict = districtId => async dispatch => {
  await CountryService.deleteDistrict(districtId);
  dispatch({
    type: DELETE_PLACE,
    payload: { data: districtId, type: 'districts' },
  });
};

export const createStreet = street => async dispatch => {
  const res = await CountryService.createStreet(street);
  dispatch({
    type: CREATE_PLACE,
    payload: { data: res.data.data, type: 'streets' },
  });
};

export const updateStreet = street => async dispatch => {
  const res = await CountryService.updateStreet(street);
  dispatch({
    type: UPDATE_PLACE,
    payload: { data: res.data.data, type: 'streets' },
  });
};

export const deleteStreet = streetId => async dispatch => {
  await CountryService.deleteStreet(streetId);
  dispatch({
    type: DELETE_PLACE,
    payload: { data: streetId, type: 'streets' },
  });
};

export const fetchBranchCountries = () => async dispatch => {
  const res = await CountryService.getBranchCountries();
  dispatch({ type: FETCH_BRANCHES_COUNTRIES, payload: res.data.data });
};
