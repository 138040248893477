import React from 'react';
import { connect } from 'react-redux';
import { Result, Button, message } from 'antd';
import { setError } from '../../redux/common/common.actions';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends React.Component {
  componentDidCatch(error, stack) {
    message.error({
      content: this.props.t('fatal_error'),
      duration: 5000,
    });
    console.error(error.message);
  }

  updatePage = () => {
    this.props.history.go(0);
  };

  componentWillUnmount() {
    this.props.setError(null);
  }

  render() {
    if (this.props.error?.status) {
      return (
        <Result
          style={{ marginTop: 70 }}
          status={this.props.error.status}
          title={this.props.error.message}
          extra={
            <Button type="primary" onClick={this.updatePage}>
              {this.props.t('try_again')}
            </Button>
          }
        />
      );
    } else if (this.props.error?.offline || !window.navigator.onLine) {
      return (
        <Result
          style={{ marginTop: 70 }}
          status="error"
          title={this.props.t('no_connect_network_error')}
          extra={
            <Button type="primary" onClick={this.updatePage}>
              {this.props.t('try_again')}
            </Button>
          }
        />
      );
    }
    return this.props.children || null;
  }
}

export const CustomErrorBoundary = compose(
  withRouter,
  connect(state => ({ error: state.common.error }), { setError }),
  withTranslation(),
)(ErrorBoundary);
