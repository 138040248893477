import { http } from './api.service';

export const OffersService = {
  getOffers(params) {
    return http.get('/offers', {
      params,
    });
  },

  createOffers(offer) {
    return http.post('/offers', offer);
  },

  updateOffer(offer) {
    return http.put(`/offers/${offer.id}`, offer);
  },

  deleteOffer(id) {
    return http.delete(`/offers/${id}`);
  },

  getOrderOffers(data) {
    return http.get('/orders/offers-for-update/' + data.id);
  },
};
