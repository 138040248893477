import { crudReducer } from '../../utils/getter';
import {
  SET_LOYALTY_QUERY_PARAMS,
  SET_NEWSLETTER_QUERY_PARAMS,
  SET_PROMO_CODE_QUERY_PARAMS,
} from './marketing.actions';

const initialState = {
  promoCodes: [],
  promoCode: null,
  promoCodeQueryParams: {},
  newsletters: [],
  newsletter: null,
  newsletterQueryParams: {},
  loyalties: [],
  loyalty: null,
  loyaltyQueryParams: {},
};

export const MarketingReducer = (state = initialState, { type, payload }) => {
  const isEmpty = payload && !Object.keys(payload).length;
  /**
   * FETCH_${MODEL_PLURAL}: fetch all models or push
   * FETCH_${MODEL}: fetch one model by id
   * CREATE_${MODEL}: create model
   * UPDATE_${MODEL}: update model by id
   * DELETE_${MODEL}: delete model by id
   */
  const crudPromoCodes = crudReducer({
    state,
    actions: { type, payload },
    model: 'promoCode',
  });
  const crudNewsletters = crudReducer({
    state,
    actions: { type, payload },
    model: 'newsletter',
  });
  const crudLoyalty = crudReducer({
    state,
    actions: { type, payload },
    model: 'loyalty',
  });
  if (crudPromoCodes) return crudPromoCodes;
  if (crudNewsletters) return crudNewsletters;
  if (crudLoyalty) return crudLoyalty;
  switch (type) {
    case SET_PROMO_CODE_QUERY_PARAMS:
      return {
        ...state,
        promoCodeQueryParams: isEmpty
          ? initialState.promoCodeQueryParams
          : {
              ...state.promoCodeQueryParams,
              ...payload,
            },
      };
    case SET_NEWSLETTER_QUERY_PARAMS:
      return {
        ...state,
        newsletterQueryParams: isEmpty
          ? initialState.newsletterQueryParams
          : {
              ...state.newsletterQueryParams,
              ...payload,
            },
      };
    case SET_LOYALTY_QUERY_PARAMS:
      return {
        ...state,
        loyaltyQueryParams: isEmpty
          ? initialState.loyaltyQueryParams
          : {
              ...state.loyaltyQueryParams,
              ...payload,
            },
      };
    default:
      return state;
  }
};
