import { http } from './api.service';

export const CategoryService = {
  getCategories(params) {
    return http.get('/categories', {
      params,
    });
  },
  getCategorieChildren(id) {
    return http.get(`/categories/children/${id}`);
  },
  createCategory(category) {
    return http.post('/categories', category);
  },
  updateCategory(category) {
    return http.put(`/categories/${category.id}`, category);
  },
  selectCategory(category) {
    return http.get(`/categories/children/${category}`);
  },
  deleteCategory(id) {
    return http.delete(`/categories/${id}`);
  },
  deleteCategories(ids) {
    return http.delete(`/categories/${ids.join(',')}`);
  },
  fetchCategoryTypes() {
    return http.get('/categories/types');
  },
  fetchCategoryParents(params) {
    return http.get('/categories', {
      params,
    });
  },
};
