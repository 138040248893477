import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.service';
import { setError, SET_LOADING } from '../common/common.actions';

export const BEGIN_AUTH = 'BEGIN_AUTH';
export const SUCCESS_AUTH = 'SUCCESS_AUTH';
export const FAIL_AUTH = 'FAIL_AUTH';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const GET_SETTINGS = 'GET_SETTINGS';
export const UPDATE_SITE = 'UPDATE_SITE';

export const FETCH_ME = 'FETCH_ME';
export const HAS_BRANCH = 'HAS_BRANCH';

export const authLogin = (username, password) => async dispatch => {
  dispatch({ type: BEGIN_AUTH });
  try {
    const res = await AuthService.login({
      username,
      password,
    });

    StorageService.setToken(res.data?.token);
    dispatch({ type: SUCCESS_AUTH });
  } catch (e) {
    dispatch({ type: FAIL_AUTH, payload: e.message });
    throw e;
  }
};

export const authLogout = () => {
  StorageService.logout();
  return {
    type: AUTH_LOGOUT,
  };
};

export const getSettings = () => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  try {
    const res = await AuthService.getSettings();
    dispatch({
      type: GET_SETTINGS,
      payload: res.data.data,
    });
    return res.data;
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch({ type: SET_LOADING, payload: false });
  }
};

export const updateSite = site => async dispatch => {
  const res = await AuthService.updateSite(site);

  await dispatch({ type: UPDATE_SITE, payload: res.data.data });
  return res.data.message;
};

export const fetchMe = () => async dispatch => {
  const res = await AuthService.getMe();
  dispatch({ type: FETCH_ME, payload: res.data.data });
  return res.data.data;
};
