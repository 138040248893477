import { BoxesService } from '../../services/boxes.service';

export const FETCH_BOXES = 'FETCH_BOXES';
export const FETCH_BOXES_BY_STATUS = 'FETCH_BOXES_BY_STATUS';
export const FETCH_BOXES_STATUSES = 'FETCH_BOXES_STATUSES';
export const FETCH_BOXES_FILTERS = 'FETCH_BOXES_FILTERS';
export const FETCH_BOXES_INFO = 'FETCH_BOXES_INFO';
export const CREATE_BOX = 'CREATE_BOX';
export const UPDATE_BOX = 'UPDATE_BOX';
export const DELETE_BOX = 'DELETE_BOX';
export const UPDATE_BOX_FLIGHT = 'UPDATE_BOX_FLIGHT';
export const DELETE_BOXES = 'DELETE_BOXES';
export const SET_BOX_QUERY_PARAM = 'SET_BOX_QUERY_PARAM';
export const REQUEST_BOXES_BY_STATUS = 'REQUEST_BOXES_BY_STATUS';
export const MOVE_BOX = 'MOVE_BOX';
export const REORDER_BOX = 'REORDER_BOX';
export const FETCH_BOX_TYPES = 'FETCH_BOX_TYPES';
export const DELETE_BOX_ORDER = 'DELETE_BOX_ORDER';
export const SET_SHOW_TYPE = 'SET_SHOW_TYPE';
export const UPDATE_KANBAN_BOX_STATUS_BY_IDS = 'UPDATE_BOX_STATUS_BY_IDS';
export const UPDATE_LIST_BOX_STATUS_BY_IDS = 'UPDATE_LIST_BOX_STATUS_BY_IDS';

export const fetchBoxes = (params, resetData = true) => async dispatch => {
  const res = await BoxesService.getBoxes(params);
  dispatch({
    type: FETCH_BOXES,
    payload: {
      data: res.data.data,
      resetData,
      info: res.data.info,
      dataLength: res.data.meta.total,
    },
  });
};

export const fetchBoxesByStatus = (
  status,
  params,
  resetData = true,
) => async dispatch => {
  dispatch({
    type: REQUEST_BOXES_BY_STATUS,
    payload: { key: status, value: true },
  });
  const res = await BoxesService.getBoxes(params);
  dispatch({
    type: FETCH_BOXES_BY_STATUS,
    payload: {
      status,
      data: res.data.data,
      resetData,
    },
  });
  dispatch({
    type: REQUEST_BOXES_BY_STATUS,
    payload: { key: status, value: false },
  });
  return res.data;
};

export const fetchBoxesStatuses = params => async dispatch => {
  const res = await BoxesService.getBoxesStatuses(params);
  dispatch({
    type: FETCH_BOXES_STATUSES,
    payload: res.data.data,
  });
};

export const fetchBoxTypes = params => async dispatch => {
  const res = await BoxesService.getBoxTypes();
  dispatch({
    type: FETCH_BOX_TYPES,
    payload: res.data.data,
  });
};

export const fetchBoxesFilters = params => async dispatch => {
  const res = await BoxesService.getBoxesFilters(params);
  dispatch({
    type: FETCH_BOXES_FILTERS,
    payload: res.data.data,
  });
};

export const updateBoxStatusById = (boxesIds, box, prevStatus) => async (
  dispatch,
  getState,
) => {
  const res = await BoxesService.updateBoxStatusById(boxesIds, box);
  const state = getState();
  if (prevStatus) {
    if (state.boxes.show_type === 'kanban') {
      dispatch({
        type: UPDATE_KANBAN_BOX_STATUS_BY_IDS,
        payload: {
          prevStatus: prevStatus,
          nextStatus: box.status_id,
          data: res.data.data,
        },
      });
    } else {
      dispatch({
        type: UPDATE_LIST_BOX_STATUS_BY_IDS,
        payload: {
          prevStatus: prevStatus,
          nextStatus: box.status_id,
          data: res.data.data,
        },
      });
    }
  }
  return res.data;
};

export const createBox = box => async dispatch => {
  const res = await BoxesService.createBox(box);
  dispatch({
    type: CREATE_BOX,
    payload: res.data.data,
  });
  return res.data;
};

export const updateBox = box => async dispatch => {
  const res = await BoxesService.updateBox(box);
  dispatch({
    type: UPDATE_BOX,
    payload: res.data.data,
  });
  return res.data;
};

export const deleteBox = item => async dispatch => {
  const res = await BoxesService.deleteBox(item.id);
  dispatch({
    type: DELETE_BOX,
    payload: { ...res.data.data[0], oldStatus: item.status },
  });
  return res.data.message;
};

export const deleteBoxes = ids => async dispatch => {
  const res = await BoxesService.deleteBox(ids.join(','));
  dispatch({
    type: DELETE_BOX,
    payload: ids,
  });
  return res.data.message;
};

export const deleteBoxOrder = (box, item) => async dispatch => {
  const res = await BoxesService.deleteBoxOrder(item.id);
  dispatch({ type: DELETE_BOX_ORDER, payload: { box, item } });
  return res.data;
};
