import { PageService } from '../../services/pages.service';
import { setError } from '../common/common.actions';

export const FETCHING_PAGES = 'FETCHING_PAGES';
export const FETCH_PAGES = 'FETCH_PAGES';
export const PUSH_PAGES = 'PUSH_PAGES';
export const CREATE_PAGE = 'CREATE_PAGE';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const DELETE_PAGE = 'DELETE_PAGE';
export const DELETE_PAGES = 'DELETE_PAGES';

export const fetchPages = (params) => async (dispatch) => {
  dispatch({ type: FETCHING_PAGES, payload: true });
  try {
    const res = await PageService.getPages(params);

    dispatch({
      type: params?.page > 1 ? PUSH_PAGES : FETCH_PAGES,
      payload: {
        data: res.data.data,
        dataLength: res.data.meta?.total || 0,
      },
    });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch({ type: FETCHING_PAGES, payload: false });
  }
};

export const createPage = (data) => async (dispatch) => {
  const res = await PageService.createPage(data);

  await dispatch({ type: CREATE_PAGE, payload: res.data.data });
  return res.data.message;
};

export const updatePage = (data) => async (dispatch) => {
  const res = await PageService.updatePage(data);

  await dispatch({ type: UPDATE_PAGE, payload: res.data.data });
  return res.data.message;
};

export const deletePage = (data) => async (dispatch) => {
  const res = await PageService.deletePage(data.id);

  await dispatch({ type: DELETE_PAGE, payload: data.id });
  return res.data.message;
};

export const deletePages = (ids) => async (dispatch) => {
  const res = await PageService.deletePages(ids);

  await dispatch({ type: DELETE_PAGES, payload: ids });
  return res.data.message;
};
