import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Routes } from './routes/routes';
import { getterState } from './utils/getter';
import { objectToQueryString } from './utils/query-string';

export const App = withRouter(({ history }) => {
  const loggedIn = useSelector(getterState('auth.loggedIn'));
  useEffect(() => {
    if (!loggedIn) {
      const search = objectToQueryString({
        redirectTo:
          history.location.pathname !== '/login'
            ? history.location.pathname
            : '',
      });
      history.push({
        pathname: '/login',
        search,
      });
    }
  }, [loggedIn]);
  return (
    <div className="App">
      <Routes />
    </div>
  );
});
