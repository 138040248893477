import { MarketingService } from '../../services/marketing.service';

export const FETCH_PROMO_CODES = 'FETCH_PROMOCODES';
export const CREATE_PROMO_CODE = 'CREATE_PROMOCODE';
export const SET_PROMO_CODE_QUERY_PARAMS = 'SET_PROMO_CODE_QUERY_PARAMS';
export const UPDATE_PROMO_CODE = 'UPDATE_PROMOCODE';
export const DELETE_PROMO_CODE = 'DELETE_PROMOCODE';

export const FETCH_NEWSLETTERS = 'FETCH_NEWSLETTERS';
export const CREATE_NEWSLETTER = 'CREATE_NEWSLETTER';
export const SET_NEWSLETTER_QUERY_PARAMS = 'SET_NEWSLETTER_QUERY_PARAMS';
export const UPDATE_NEWSLETTER = 'UPDATE_NEWSLETTER';
export const DELETE_NEWSLETTER = 'DELETE_NEWSLETTER';
// loyalties
export const FETCH_LOYALTIES = 'FETCH_LOYALTIES';
export const CREATE_LOYALTY = 'CREATE_LOYALTY';
export const SET_LOYALTY_QUERY_PARAMS = 'SET_LOYALTY_QUERY_PARAMS';
export const UPDATE_LOYALTY = 'UPDATE_LOYALTY';
export const DELETE_LOYALTY = 'DELETE_LOYALTY';

export const fetchLoyalties = (params, resetData = true) => async dispatch => {
  const res = await MarketingService.getCashbackList(params);
  dispatch({
    type: FETCH_LOYALTIES,
    payload: {
      data: res.data?.data || [],
      resetData,
    },
  });
  return res.data;
};

export const fetchPromoCodes = (params, resetData = true) => async dispatch => {
  const res = await MarketingService.fetchPromoCodes(params);
  dispatch({
    type: FETCH_PROMO_CODES,
    payload: {
      data: res.data?.data || [],
      resetData,
    },
  });
  return res.data;
};

export const createPromoCode = (data, params) => async dispatch => {
  const res = await MarketingService.createPromoCode(data, params);
  dispatch({
    type: CREATE_PROMO_CODE,
    payload: res.data.data,
  });
  return res.data;
};

export const updatePromoCode = (data, params) => async dispatch => {
  const res = await MarketingService.updatePromoCode(data, params);
  dispatch({
    type: UPDATE_PROMO_CODE,
    payload: res.data.data,
  });
  return res.data;
};

export const deletePromoCode = (ids, params) => async dispatch => {
  const res = await MarketingService.deletePromoCode(ids.join(','), params);
  ids.forEach(id => {
    dispatch({
      type: DELETE_PROMO_CODE,
      payload: { id },
    });
  });
  return res.data;
};

export const fetchNewsletters = (
  params,
  resetData = true,
) => async dispatch => {
  const res = await MarketingService.fetchNewsletters(params);
  dispatch({
    type: FETCH_NEWSLETTERS,
    payload: {
      data: res.data?.data || [],
      resetData,
    },
  });
  return res.data;
};

export const createNewsletter = (data, params) => async dispatch => {
  const res = await MarketingService.createNewsletter(data, params);
  dispatch({
    type: CREATE_NEWSLETTER,
    payload: res.data.data,
  });
  return res.data;
};

export const updateNewsletter = (id, data, params) => async dispatch => {
  const res = await MarketingService.updateNewsletter(id, data, params);
  dispatch({
    type: UPDATE_NEWSLETTER,
    payload: res.data.data,
  });
  return res.data;
};
export const updateNewsletterLecture = data => async dispatch => {
  const res = await MarketingService.updateNewsletterLecture(data);
  dispatch({
    type: UPDATE_NEWSLETTER,
    payload: res.data.data,
  });
  return res.data;
};

export const deleteNewsletter = (ids, params) => async dispatch => {
  const res = await MarketingService.deleteNewsletter(ids.join(','), params);
  ids.forEach(id => {
    dispatch({
      type: DELETE_NEWSLETTER,
      payload: { id },
    });
  });
  return res.data;
};

export const deleteNewsletterImage = id => async dispatch => {
  const res = await MarketingService.deleteNewsletterImage(id);
  dispatch({
    type: UPDATE_NEWSLETTER,
    payload: res.data.data,
  });
  return res.data;
};

export const startNewsletter = (id, params) => async dispatch => {
  const res = await MarketingService.startNewsletter(id, params);
  dispatch({
    type: UPDATE_NEWSLETTER,
    payload: res.data.data,
  });
  return res.data;
};

export const stopNewsletter = (id, params) => async dispatch => {
  const res = await MarketingService.stopNewsletter(id, params);
  dispatch({
    type: UPDATE_NEWSLETTER,
    payload: res.data.data,
  });
  return res.data;
};
