import { NEW_TASK } from '../../constants/task';
import { SET_TASK_MODAL_DATA, SET_TASK_QUERY_PARAM } from './tasks.actions';

const initialState = {
  queryParams: {
    status_id: NEW_TASK,
  },
  modalData: {
    items: [{ category: null }],
  },
};

export const TasksReducer = (state = initialState, { type, payload }) => {
  const isEmpty = payload && !Object.keys(payload).length;
  switch (type) {
    case SET_TASK_QUERY_PARAM:
      return {
        ...state,
        queryParams: isEmpty
          ? initialState.queryParams
          : {
              ...state.queryParams,
              ...payload,
            },
      };
    case SET_TASK_MODAL_DATA:
      return {
        ...state,
        modalData: isEmpty
          ? initialState.modalData
          : {
              ...state.modalData,
              ...payload,
            },
      };
    default:
      return state;
  }
};
