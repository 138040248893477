import { Button, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const NotFoundPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <Result
      status="404"
      title={t('page_notfound')}
      style={{ marginTop: 50 }}
      extra={
        <Button type="primary" onClick={() => history.push('/admin')}>
          {t('home')}
        </Button>
      }
    />
  );
};
