import { http } from './api.service';

export const FlightService = {
  getFlights(params) {
    return http.get('/flights', {
      params,
    });
  },
  getOrders(id, params) {
    return http.get(`/flights/${id}/orders`, { params });
  },
  createFlight() {
    return http.post('/flights');
  },
  updateFlight(data) {
    return http.put(`/flights/${data.id}`, data);
  },
  updateFlights(data) {
    return http.put(`/flights/update-status/${data.ids.join(',')}`, data);
  },
  deleteFlight(id) {
    return http.delete(`/flights/${id}`);
  },
  deleteFlights(ids) {
    return http.delete(`/flights/${ids.join(',')}`);
  },
  getFlightFilters() {
    return http.get('/flights/filter-list');
  },
  getManifest(data) {
    return http.post(`/export/${data.ext}/${data.type}`, data);
  },
  getFlightDocs(id, params) {
    return http.get(`/flights/${id}/docs`, { params });
  },
  removeDoc(flight, doc) {
    return http.delete(`/flights/${flight}/docs/${doc}`);
  },
};
