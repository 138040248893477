import {
  CREATE_PAGE,
  DELETE_PAGE,
  DELETE_PAGES,
  FETCHING_PAGES,
  FETCH_PAGES,
  PUSH_PAGES,
  UPDATE_PAGE,
} from './page.actions';

const initialState = {
  pages: [],
  pagesLength: 0,
  loading: false,
};

export const PagesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCHING_PAGES:
      return {
        ...state,
        loading: payload,
      };
    case FETCH_PAGES:
      return {
        ...state,
        pages: payload.data,
        pagesLength: payload.dataLength,
      };
    case PUSH_PAGES:
      return {
        ...state,
        pages: [...state.pages, ...payload.data],
        pagesLength: payload.dataLength,
      };
    case CREATE_PAGE:
      return {
        ...state,
        pages: [payload, ...state.pages],
        pagesLength: state.pagesLength + 1,
      };
    case UPDATE_PAGE:
      return {
        ...state,
        pages: state.pages.map((item) =>
          item.id === payload.id ? payload : item,
        ),
      };
    case DELETE_PAGE:
      return {
        ...state,
        pages: state.pages.filter((item) => item.id !== payload),
        pagesLength: state.pagesLength - 1,
      };
    case DELETE_PAGES:
      return {
        ...state,
        pages: state.pages.filter((item) => !payload.includes(item.id)),
        pagesLength: state.pagesLength - payload.length,
      };
    default:
      return state;
  }
};
