import {
  CREATE_OFFER,
  DELETE_OFFER,
  FETCH_OFFERS,
  UPDATE_OFFER,
} from './offers.actions';

const initialState = {
  offers: [],
};

export const OffersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_OFFERS:
      return { ...state, offers: payload };
    case UPDATE_OFFER:
      return {
        ...state,
        offers: state.offers.map((offer) => {
          if (offer.id === payload.id) {
            return payload;
          }
          return offer;
        }),
      };
    case CREATE_OFFER:
      return { ...state, offers: [...state.offers, payload] };
    case DELETE_OFFER:
      return {
        ...state,
        offers: state.offers.filter((offer) => offer.id !== payload),
      };
    default:
      return state;
  }
};
