import { http } from './api.service';

export const StatusesService = {
  getStatuses(params) {
    return http.get('/statuses', {
      params,
    });
  },
  createStatus(status) {
    return http.post('/statuses', status);
  },
  updateStatus(status) {
    return http.put(`/statuses/${status.id}`, status);
  },
  deleteStatus(id) {
    return http.delete(`/statuses/${id}`);
  },
};
