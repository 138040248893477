import { ShopService } from '../../services/shops.service';
import { setError } from '../common/common.actions';

export const FETCHING_SHOPS = 'FETCHING_SHOPS';
export const FETCH_SHOPS = 'FETCH_SHOPS';
export const PUSH_SHOPS = 'PUSH_SHOPS';
export const CREATE_SHOP = 'CREATE_SHOP';
export const UPDATE_SHOP = 'UPDATE_SHOP';
export const DELETE_SHOP = 'DELETE_SHOP';

// export const FETCH_CATEGORY_TYPES = 'FETCH_CATEGORY_TYPES';

export const fetchShops = (params) => async (dispatch) => {
  dispatch({ type: FETCHING_SHOPS, payload: true });
  try {
    const res = await ShopService.getShops(params);

    dispatch({
      type: params?.page > 1 ? PUSH_SHOPS : FETCH_SHOPS,
      payload: {
        data: res.data.data,
        dataLength: res.data.meta?.total || 0,
      },
    });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch({ type: FETCHING_SHOPS, payload: false });
  }
};

export const createShop = (shop) => async (dispatch) => {
  const res = await ShopService.createShop(shop);

  await dispatch({ type: CREATE_SHOP, payload: res.data.data });
  return res.data.message;
};

export const updateShop = (shop) => async (dispatch) => {
  const res = await ShopService.updateShop(shop);

  await dispatch({ type: UPDATE_SHOP, payload: res.data.data });
  return res.data.message;
};

export const deleteShop = (shop) => async (dispatch) => {
  const res = await ShopService.deleteShop(shop.id);

  await dispatch({ type: DELETE_SHOP, payload: shop.id });
  return res.data.message;
};
