import { http } from './api.service';

export const UserService = {
  getUsers(params) {
    return http.get('/users', {
      params,
    });
  },
  createUser(user) {
    return http.post('/users', user);
  },
  updateUser(user) {
    return http.put(`/users/${user.id}`, user);
  },
  deleteUser(id) {
    return http.delete(`/users/${id}`);
  },
  getRoles(params) {
    return http.get('/roles', {
      params,
    });
  },
};
