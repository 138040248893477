import {
  FETCH_STATUS_TYPE,
  CREATE_STATUS,
  UPDATE_STATUS,
  DELETE_STATUS,
} from './statuses.actions';

const initialState = {
  statusTypes: [],
};

export const StatusesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_STATUS_TYPE:
      return { ...state, statusTypes: payload };
    case UPDATE_STATUS:
      return {
        ...state,
        statusTypes: state.statusTypes.map((s_type) => {
          if (payload.type.id === s_type.id) {
            const copyPayload = { ...payload };
            delete copyPayload.type;
            return {
              ...s_type,
              statuses: s_type.statuses.map((status) => {
                if (status.id === payload.id) {
                  return copyPayload;
                }
                return status;
              }),
            };
          }
          return s_type;
        }),
      };
    case CREATE_STATUS:
      return {
        ...state,
        statusTypes: state.statusTypes.map((type) => {
          if (payload.type.id === type.id) {
            const copyPayload = { ...payload };
            delete copyPayload.type;
            return {
              ...type,
              statuses: [...type.statuses, copyPayload],
            };
          }
          return type;
        }),
      };
    case DELETE_STATUS:
      return {
        ...state,
        statusTypes: state.statusTypes.map((s_type) => {
          if (payload.type === s_type.id) {
            return {
              ...s_type,
              statuses: s_type.statuses.filter(
                (status) => status.id !== payload.id,
              ),
            };
          }
          return s_type;
        }),
      };
    default:
      return state;
  }
};
