import {
  CREATE_CLIENT,
  CREATE_CLIENT_ADDRESS,
  DELETE_CLIENTS,
  DELETE_CLIENT_ADDRESS,
  FETCH_CLIENTS,
  FETCH_CLIENT_FILTERS,
  FETCH_CLIENT_INFO,
  UPDATE_CLIENT,
  UPDATE_CLIENT_ADDRESS,
  SET_CLIENT_QUERY_PARAM,
  FETCH_CLIENT_ORDERS,
  RESET_CLIENT_ORDERS,
  UPDATE_CLIENT_LOCATION,
  FETCH_CLIENT_LOCATION,
} from './client.actions';

const initialState = {
  clients: [],
  filters: [],
  info: [],
  queryParams: {},
  clientsLength: 0,
  clientOrders: [],
  clientOrdersLastPage: 1,
  location: null,
};

export const ClientReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CLIENTS:
      return {
        ...state,
        clients: payload.resetData
          ? payload.data
          : [...state.clients, ...payload.data],
        clientsLength: payload.dataLength,
      };
    case FETCH_CLIENT_FILTERS:
      return { ...state, filters: payload };
    case FETCH_CLIENT_INFO:
      return { ...state, info: payload };
    case FETCH_CLIENT_ORDERS:
      const updatedData = payload.data.map(payloadYearData => {
        const hasYear = state.clientOrders.find(
          yearOrders => payloadYearData.name === yearOrders.name,
        );
        if (hasYear) {
          return {
            ...hasYear,
            quarters: hasYear.quarters?.length
              ? [...hasYear.quarters, ...payloadYearData.quarters]
              : payloadYearData.quarters,
          };
        }

        return payloadYearData;
      });
      const haveYears = state.clientOrders.map(clientOrder => clientOrder.name);
      return {
        ...state,
        clientOrdersLastPage: payload?.meta?.last_page,
        clientOrders: payload.resetData
          ? payload.data
          : [
              ...state.clientOrders.map(clientOrder => {
                return (
                  updatedData.find(data => data.name === clientOrder.name) ||
                  clientOrder
                );
              }),
              ...updatedData.filter(data => !haveYears.includes(data.name)),
            ],
      };
    case RESET_CLIENT_ORDERS:
      return {
        ...state,
        clientOrders: initialState.clientOrders,
        clientOrdersLastPage: initialState.clientOrdersLastPage,
      };
    case SET_CLIENT_QUERY_PARAM:
      const isEmpty = payload && !Object.keys(payload).length;
      return {
        ...state,
        queryParams: isEmpty
          ? {}
          : {
              ...state.queryParams,
              ...payload,
            },
      };
    case CREATE_CLIENT:
      return {
        ...state,
        clients: [payload, ...state.clients],
        clientsLength: state.clientsLength + 1,
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        clients: state.clients.map(client =>
          client.id === payload.id ? payload : client,
        ),
      };
    case DELETE_CLIENTS:
      return {
        ...state,
        clients: state.clients.filter(client => !payload.includes(client.id)),
        clientsLength: state.clientsLength - 1,
      };
    case CREATE_CLIENT_ADDRESS:
      return {
        ...state,
        clients: state.clients.map(client => {
          if (client.id === payload.id) {
            return {
              ...client,
              addresses: [...client.addresses, payload.address],
            };
          }
          return client;
        }),
      };
    case UPDATE_CLIENT_ADDRESS:
      return {
        ...state,
        clients: state.clients.map(client => {
          if (client.id === payload.id) {
            return {
              ...client,
              addresses: client.addresses.map(address => {
                if (address.id === payload.address.id) {
                  return payload.address;
                }
                return address;
              }),
            };
          }
          return client;
        }),
      };
    case DELETE_CLIENT_ADDRESS:
      return {
        ...state,
        clients: state.clients.map(client => {
          if (client.id === payload.id) {
            return {
              ...client,
              addresses: client.addresses.filter(
                address => address.id !== payload.address.id,
              ),
            };
          }
          return client;
        }),
      };
    case UPDATE_CLIENT_LOCATION:
      return {
        ...state,
        clients: state.clients.map(client => {
          if (client.id === payload.id) {
            return {
              ...client,
              location: [payload.location],
            };
          }
          return client;
        }),
      };
    case FETCH_CLIENT_LOCATION:
      return {
        ...state,
        clients: state.clients.map(client => {
          if (client.id === payload.id) {
            return {
              ...client,
              location: [payload.location],
            };
          }
          return client;
        }),
      };
    default:
      return state;
  }
};
