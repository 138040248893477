import { http } from './api.service';

export const BoxesService = {
  getBoxes(params) {
    return http.get('/boxes', {
      params,
    });
  },
  getBoxesStatuses(params) {
    return http.get('/statuses', {
      params,
    });
  },
  getBoxTypes(params) {
    return http.get('/box-types', {
      params,
    });
  },
  updateBoxStatusById(boxesIds, box) {
    return http.put(`/boxes/update-status/${boxesIds.join(',')}`, box);
  },
  getBoxesFilters() {
    return http.get('/boxes/filter-list');
  },
  createBox(box) {
    return http.post('/boxes', box);
  },
  updateBox(box) {
    return http.put(`/boxes/${box.id}`, box);
  },
  deleteBox(id) {
    return http.delete(`/boxes/${id}`);
  },
  deleteBoxOrder(orderId) {
    return http.delete(`/box-orders/${orderId}`);
  },
};
