import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { authLogin } from '../../redux/auth/authAction';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, message, Alert } from 'antd';
import { SVGIcon } from '../../components/SVGIcon/SVGIcon';
import { useTranslation } from 'react-i18next';
import { stringQueryToObject } from '../../utils/query-string';
import '../../containers/Main/Main.scss';
import './formLogin.scss';

function AuthLogin({ authLogin, loggedIn }) {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errors, setComponentErrors] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (loggedIn) {
      history.push('/admin');
    }
  }, [history, loggedIn]);

  const handleSubmit = async values => {
    const toolTipKey = 'login-modal';
    setLoading(true);
    setComponentErrors(null);
    try {
      await authLogin(values.username, values.password);
      const queryParam = stringQueryToObject(history.location.search);
      history.push(queryParam.redirectTo ? queryParam.redirectTo : '/admin');
    } catch (error) {
      if (error.response?.status === 422) {
        setComponentErrors(error.response.data.errors);
      } else {
        message.error({
          content: error.errorFields?.length
            ? t('all_fields_required')
            : error.message,
          key: toolTipKey,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form className="formAuth" form={form} onFinish={handleSubmit}>
      <SVGIcon className="logo" iconPath="logo" />
      <div className="containerFormLogin">
        <h5>{t('login_text')}</h5>
        <Form.Item
          name="username"
          label={t('username')}
          rules={[
            {
              required: true,
              message: t('required.field'),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <div className="passwordAuth">
          <Form.Item
            name="password"
            label={t('password')}
            rules={[
              {
                required: true,
                message: t('required.field'),
              },
            ]}
          >
            <Input.Password
              iconRender={visible =>
                visible ? (
                  <SVGIcon className="show" iconPath="show" />
                ) : (
                  <SVGIcon className="show" iconPath="hide" />
                )
              }
            />
          </Form.Item>
        </div>

        <div className="m-b-10">
          {errors &&
            Object.keys(errors).map(field => (
              <Alert
                key={field}
                message={errors[field].join('\n')}
                type="error"
                showIcon
              />
            ))}
        </div>
        <Form.Item>
          <Button
            block
            loading={loading}
            className="buttonAdd"
            type="primary"
            htmlType="submit"
          >
            {t('login')}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

const mapState = state => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapState, { authLogin })(AuthLogin);
