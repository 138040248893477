import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Pluralize } from './format-unit';

export const getterState = stateString => state =>
  stateString.split('.').reduce((acc, val) => acc && acc[val], state);

export const useMapState = (arrStateString, selector) =>
  arrStateString.map(stateString => selector(getterState(stateString)));

export const getNameByPrefix = (...args) =>
  args[0] ? args.join('_') : args.slice(1).join('_');

export const useCan = roles => {
  const user = useSelector(getterState('auth.user'));
  const hasAccess = useMemo(() => roles.includes(user?.role?.id), [
    roles,
    user,
  ]);
  return hasAccess;
};

export const crudReducer = params => {
  const {
    state,
    actions: { type, payload },
    model,
  } = params;
  if (!state || !model || !type) throw new Error('invalid params');
  switch (type) {
    case `FETCH_${Pluralize(model).toUpperCase()}`:
      return {
        ...state,
        [Pluralize(model)]: payload.resetData
          ? payload.data
          : [...state[Pluralize(model)], ...payload.data],
      };
    case `FETCH_${model.toUpperCase()}`:
      return {
        ...state,
        [model]: payload,
      };
    case `CREATE_${model.toUpperCase()}`:
      return {
        ...state,
        [Pluralize(model)]: [payload, ...state[Pluralize(model)]],
      };
    case `UPDATE_${model.toUpperCase()}`:
      return {
        ...state,
        [Pluralize(model)]: state[[Pluralize(model)]].map(item => {
          if (item.id === payload.id) {
            return payload;
          }
          return item;
        }),
      };
    case `DELETE_${model.toUpperCase()}`:
      return {
        ...state,
        [Pluralize(model)]: state[Pluralize(model)].filter(
          item => item.id !== payload.id,
        ),
      };
    default:
      return null;
  }
};
