import { CellService } from '../../services/cell.service';
import { setError } from '../common/common.actions';

export const FETCHING_CELLS = 'FETCHING_CELLS';
export const FETCH_CELLS = 'FETCH_CELLS';
export const CREATE_CELL = 'CREATE_CELL';
export const UPDATE_CELL = 'UPDATE_CELL';
export const DELETE_CELL = 'DELETE_CELL';
export const PUSH_CELLS = 'PUSH_CATEGORIES';

export const fetchCells = params => async dispatch => {
  dispatch({ type: FETCHING_CELLS, payload: true });
  try {
    const res = await CellService.getCells(params);

    dispatch({
      type: params?.page > 1 ? PUSH_CELLS : FETCH_CELLS,
      payload: {
        data: res.data.data,
        dataLength: res.data.meta?.total || 0,
      },
    });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch({ type: FETCHING_CELLS, payload: false });
  }
};

export const createCell = cell => async dispatch => {
  const res = await CellService.createCell(cell);
  await dispatch({ type: CREATE_CELL, payload: res.data.data });
  return res.data.message;
};

export const getOrdersForCell = id => async dispatch => {
  const res = await CellService.getOrdersForCell(id);
  return res.data.data;
};

export const updateCell = cell => async dispatch => {
  const res = await CellService.updateCell(cell);

  await dispatch({ type: UPDATE_CELL, payload: res.data.data });
  return res.data.message;
};

export const deleteCell = cell => async dispatch => {
  const res = await CellService.deleteCell(cell.id);

  await dispatch({ type: DELETE_CELL, payload: cell.id });
  return res.data.message;
};
