import { FlightService } from '../../services/flight.service';
import { setError } from '../common/common.actions';

export const FETCHING_FLIGHTS = 'FETCHING_FLIGHTS';
export const FETCH_FLIGHTS = 'FETCH_FLIGHTS';
export const PUSH_FLIGHTS = 'PUSH_FLIGHTS';
export const CREATE_FLIGHT = 'CREATE_FLIGHT';
export const UPDATE_FLIGHT = 'UPDATE_FLIGHT';
export const UPDATE_FLIGHTS = 'UPDATE_FLIGHTS';
export const DELETE_FLIGHT = 'DELETE_FLIGHT';
export const DELETE_FLIGHTS = 'DELETE_FLIGHTS';
export const FETCH_FLIGHT_FILTERS = 'FETCH_FLIGHT_FILTERS';

export const SET_FLIGHT_QUERY_PARAM = 'SET_FLIGHT_QUERY_PARAM';

export const fetchFlights = params => async dispatch => {
  dispatch({ type: FETCHING_FLIGHTS, payload: true });
  try {
    const res = await FlightService.getFlights(params);

    dispatch({
      type: params?.page > 1 ? PUSH_FLIGHTS : FETCH_FLIGHTS,
      payload: {
        data: res.data?.data || [],
        info: res.data?.info || [],
        dataLength: res.data.meta?.total || 0,
      },
    });

    return res.data;
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch({ type: FETCHING_FLIGHTS, payload: false });
  }
};

export const createFlight = () => async dispatch => {
  const res = await FlightService.createFlight();
  dispatch({
    type: CREATE_FLIGHT,
    payload: res.data.data,
  });
  return res.data.message;
};

export const updateFlight = flight => async dispatch => {
  const res = await FlightService.updateFlight(flight);

  await dispatch({ type: UPDATE_FLIGHT, payload: res.data.data });
  return res.data.message;
};

export const updateFlights = flight => async dispatch => {
  const res = await FlightService.updateFlights(flight);

  await dispatch({ type: UPDATE_FLIGHTS, payload: res.data.data });
  return res.data;
};

export const deleteFlight = id => async dispatch => {
  const res = await FlightService.deleteFlight(id);

  await dispatch({ type: DELETE_FLIGHT, payload: id });
  return res.data.message;
};

export const deleteFlights = ids => async dispatch => {
  const res = await FlightService.deleteFlight(ids);

  await dispatch({ type: DELETE_FLIGHTS, payload: ids });
  return res.data.message;
};

export const fetchFlightFilters = () => async dispatch => {
  const res = await FlightService.getFlightFilters();
  dispatch({ type: FETCH_FLIGHT_FILTERS, payload: res.data.data });
};
