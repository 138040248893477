import axios from 'axios';
import { http } from './api.service';

export const OrderService = {
  getApplications(params) {
    return http.get('/applications', { params });
  },
  getNewApplicationCount() {
    return http.get(`/applications/news/count`);
  },
  getApplication(id, params) {
    return http.get(`/applications/${id}`, { params });
  },
  getOrders(params) {
    return http.get('/orders', {
      params,
    });
  },
  getClient(order, params) {
    return http.get(`/orders/${order}/client`, { params });
  },

  senderUpdate(order, params) {
    return http.put(`/orders/${order}/update-sender`, params);
  },

  receiverUpdate(order, params) {
    return http.put(`/orders/${order}/update-receiver`, params);
  },
  getTrackByItem(item, params) {
    return http.get(`/orders/items/${item}/track-list`, { params });
  },
  getOrderById(order, params) {
    return http.get(`/orders/${order}`, { params });
  },
  createOrder(order) {
    return http.post('/orders', order);
  },
  updateOrder(order) {
    return http.put(`/orders/${order.id}`, order);
  },
  deleteOrders(orders) {
    return http.delete(`/orders/${orders}`);
  },
  cancelTasks(orders) {
    return http.post(`/cancel-tasks`, {
      orders: orders,
    });
  },
  getOrderFilters() {
    return http.get('/orders/filter-list');
  },
  combineOrders(sourceOrder, combinedOrders, params) {
    return http.put(
      `/orders/${sourceOrder}/combine/${combinedOrders.join(',')}/${Number(
        !!params.validate,
      )}/${Number(!!params.limit_validate)}`,
    );
  },
  getOrderHistory(id, params) {
    return http.get(`/orders/${id}/history`, { params });
  },
  getOrderTracking(id, params) {
    return http.get(`/orders/${id}/status-history`, { params });
  },
  getAvailableStatuses() {
    return http.get('/orders/available-statuses');
  },
  updateOrderStatuses(orders, status) {
    return http.put(`/orders/${orders.join(',')}/update-status/${status}`);
  },
  addToBox(box, orders) {
    return http.put(`/orders/${orders.join(',')}/add-2-box/${box}`);
  },
  addToDraft(order) {
    return http.post('/orders/draft', order);
  },
  createPickup(data) {
    return http.post('/applications/pickup', data);
  },
  createDeliveryService(data) {
    return http.post('/applications/delivery-services', data);
  },
  createHelpToShop(data) {
    return http.post('/applications/help-to-shop', data);
  },
  sendFillSMSPickup(data) {
    return http.post('/applications/pickup/send-fill-request', data);
  },
  sendFillSMSDeliveryService(data) {
    return http.post('/applications/delivery-services/send-fill-request', data);
  },
  sendFillSMSHelpToShop(data) {
    return http.post('/applications/help-to-shop/send-fill-request', data);
  },
  confirmApplication(data) {
    return http.put(`/applications/${data.model}/approve/${data.id}`, data);
  },
  updatePickup(data) {
    return http.put('/applications/pickup/' + data.id, data);
  },
  updateDeliveryService(data) {
    return http.put('/applications/delivery-services/' + data.id, data);
  },
  updateHelpToShop(data) {
    return http.put('/applications/help-to-shop/' + data.id, data);
  },
  updateInternet(data) {
    return http.put('/applications/internet/' + data.id, data);
  },
  updateSelfDelivery(data) {
    return http.put('/applications/self-delivery/' + data.id, data);
  },
  updateAppDateAndTime(data) {
    return http.put('/applications/pickup/time/' + data.id, data);
  },
  executeApplication(data) {
    return http.put(`/applications/finish/${data.id}`, data);
  },
  executeHelpToShop(data) {
    return http.put('/applications/help-to-shop/to-internet/' + data.id);
  },
  cancelApplication(data) {
    return http.put('/applications/cancel/' + data.id, data);
  },
  getApplicationOffers(data) {
    return http.get('/applications/offers-for-update/' + data.id);
  },
  getPayments(id, params) {
    return http.get(`/orders/${id}/payments`, { params });
  },
  getApplicationPayments(id, params) {
    return http.get(`/applications/${id}/payments`, { params });
  },
  sentLabel(data) {
    return http.post('/applications/send-label/' + data.id, data);
  },
  verifyLabel(data) {
    return http.post(
      '/applications/delivery-services/get-label-url/' + data.id,
    );
  },
  getShipServices(params) {
    return http.get('/orders/ship-services', { params });
  },
  getNameByUrl(data) {
    return axios.post('https://turonexpress.com/api/product', data);
  },
  getApplicationDetails(data) {
    return axios.post('https://turonexpress.com/api/application/album', data);
  },
  getImages(id, params) {
    return http.get(`/orders/${id}/images`, { params });
  },
  removeImage(id, uid) {
    return http.delete(`/orders/${id}/images/${uid}`);
  },
  applyPromoCode(id, data) {
    return http.post(`/orders/${id}/use-promo-code`, data);
  },
  deletePromoCode(id) {
    return http.delete(`/orders/${id}/delete-promo-code`);
  },
  applyAppPromoCode(id, data) {
    return http.post(`/applications/${id}/use-promo-code`, data);
  },
  deleteAppPromoCode(id) {
    return http.delete(`/applications/${id}/delete-promo-code`);
  },
  updateAppPhoto(id, data) {
    return http.post(`/applications/${id}/upload-photo`, data);
  },
  deleteAppPhoto(id) {
    return http.delete(`/applications/${id}/delete-photo`);
  },
  updateClient(order_id, data) {
    return http.put(`/orders/${order_id}/update-client`, data);
  },
  addTrack(id, data) {
    return http.post(`/orders/${id}/add-track`, data);
  },
  getCell(id) {
    return http.get(`/orders/${id}/get-cell`);
  },
  getCouriers(search) {
    return http.get(
      `users?filter[role]=courier${search && `&filter[search]=${search}`}`,
    );
  },
  getActiveShip(id) {
    return http.get(`users/${id}/active-ship`);
  },
  postAddActiveShip(id) {
    return http.post(`users/${id}/add-active-ship`);
  },
  postAddOrdersActive(id, data) {
    return http.post(`users/${id}/add-orders-to-active-ship-of-user`, data);
  },
  postAddOrdersShipment(id, data) {
    return http.post(`users/${id}/add-orders-to-user-tasks`, data);
  },
};
