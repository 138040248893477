import { Button, Result, Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ProtectedRoute } from '../components/ProtectedRoute/ProtectedRoute';
import AuthLogin from '../pages/AuthLogin/AuthLogin';
import { MainLayout } from '../containers/MainLayout/MainLayout';
import { NotFoundPage } from '../pages/NotFound';
import {
  COURIER_ROLE,
  INTERN_OPERATOR_ROLE,
  INTERN_ROLE,
  MODERATOR_ROLE,
  OPERATOR_ROLE,
  SUPER_ADMIN_ROLE,
} from '../constants/role';
import { useCan } from '../utils/getter';
import { useTranslation } from 'react-i18next';

const NoInternetConnect = () => {
  const [t] = useTranslation();
  return (
    <Result
      style={{ marginTop: 70 }}
      status="error"
      title={t('no_connect_network_error')}
      extra={<Button type="primary">{t('try_again')}</Button>}
    />
  );
};

function retry(fn, retriesLeft = 15, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(err => {
        console.error(err);
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            // reject(error);
            // return;
            resolve({
              default: NoInternetConnect,
            });
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

const SettingLayout = lazy(() =>
  retry(() =>
    import('../containers/SettingLayout/SettingLayout').then(m => ({
      default: m.SettingLayout,
    })),
  ),
);

const MarketingLayout = lazy(() =>
  retry(() =>
    import('../containers/Marketing/MarketingLayout').then(m => ({
      default: m.MarketingLayout,
    })),
  ),
);

const PromoCodes = lazy(() =>
  retry(() =>
    import('../pages/Marketing/PromoCodes').then(m => ({
      default: m.PromoCodes,
    })),
  ),
);

// const Marketing = lazy(() =>
//   retry(() =>
//     import('../pages/Marketing/Marketing').then(m => ({
//       default: m.Marketing,
//     })),
//   ),
// );

const Loyalty = lazy(() =>
  retry(() =>
    import('../pages/Marketing/Loyalty').then(m => ({
      default: m.Loyalty,
    })),
  ),
);

const Newsletters = lazy(() =>
  retry(() =>
    import('../pages/Marketing/Newsletters').then(m => ({
      default: m.Newsletters,
    })),
  ),
);

const ApplicationsPage = lazy(() =>
  retry(() =>
    import('../pages/Applications').then(m => ({
      default: m.ApplicationsPage,
    })),
  ),
);
const ApplicationShowPage = lazy(() =>
  retry(() =>
    import('../pages/ApplicationShow').then(m => ({
      default: m.ApplicationShowPage,
    })),
  ),
);
const TasksPage = lazy(() =>
  retry(() =>
    import('../pages/Tasks/Tasks').then(m => ({
      default: m.Tasks,
    })),
  ),
);
const TaskShowPage = lazy(() =>
  retry(() =>
    import('../pages/Tasks/TaskShow').then(m => ({
      default: m.TaskShow,
    })),
  ),
);
const UserBranch = lazy(() =>
  retry(() =>
    import('../pages/UserBranch/UserBranch').then(m => ({
      default: m.UserBranch,
    })),
  ),
);
const OrdersPage = lazy(() =>
  retry(() => import('../pages/Orders').then(m => ({ default: m.OrdersPage }))),
);
const WarehousePage = lazy(() =>
  retry(() =>
    import('../pages/Warehouses').then(m => ({ default: m.WarehousePage })),
  ),
);
const Boxes = lazy(() => retry(() => import('../pages/Boxes')));
const ClientsPage = lazy(() =>
  retry(() =>
    import('../pages/Clients').then(m => ({ default: m.ClientsPage })),
  ),
);
const Transactions = lazy(() =>
  retry(() =>
    import('../pages/Transactions').then(m => ({ default: m.Transactions })),
  ),
);
const Invoices = lazy(() =>
  retry(() => import('../pages/Invoices').then(m => ({ default: m.Invoices }))),
);

const CashReceipt = lazy(() => retry(() => import('../pages/CashReceipt')));

const FlightsPage = lazy(() => retry(() => import('../pages/Flights/Flights')));
const Branches = lazy(() =>
  retry(() => import('../pages/Settings/Branches/Branches')),
);
const SettingsExtraServices = lazy(() =>
  retry(() =>
    import('../pages/Settings/SettingsExtraServices/SettingsExtraServices'),
  ),
);
const Operators = lazy(() =>
  retry(() => import('../pages/Settings/Operators/Operators')),
);
const General = lazy(() =>
  retry(() => import('../pages/Settings/General/General')),
);
const Statuses = lazy(() =>
  retry(() => import('../pages/Settings/Statuses/Statuses')),
);
const CategoriesPage = lazy(() =>
  retry(() => import('../pages/Settings/Categories/Categories')),
);
const PlacesPage = lazy(() =>
  retry(() =>
    import('../pages/Settings/Places/Places').then(m => ({
      default: m.PlacesPage,
    })),
  ),
);
const ShopsPage = lazy(() =>
  retry(() => import('../pages/Settings/Shops/ShopsPage')),
);
const SheetsPage = lazy(() =>
  retry(() => import('../pages/Settings/Sheets/Sheets')),
);
const PromoCodeSetting = lazy(() =>
  retry(() => import('../pages/Settings/PromoCodeSetting/PromoCodeSetting')),
);

const Cell = lazy(() => retry(() => import('../pages/Settings/Cell/Cell')));

const Delivery = lazy(() => retry(() => import('../pages/Delivery')));

export function Routes() {
  const canMiddle = useCan([OPERATOR_ROLE, MODERATOR_ROLE, SUPER_ADMIN_ROLE]);

  return (
    <Switch>
      <Redirect exact path="/" to="/admin" />
      <Route path="/login" component={AuthLogin} />
      <Route path="/admin">
        <MainLayout>
          <Suspense fallback={<Spin className="window-center" />}>
            <Switch>
              <ProtectedRoute
                exact
                path="/admin/user-branch"
                component={UserBranch}
                can={[
                  OPERATOR_ROLE,
                  MODERATOR_ROLE,
                  SUPER_ADMIN_ROLE,
                  COURIER_ROLE,
                  INTERN_ROLE,
                  INTERN_OPERATOR_ROLE,
                ]}
              />
              <Redirect
                path="/admin"
                to={canMiddle ? '/admin/applications' : '/admin/orders'}
                exact
              />
              <ProtectedRoute
                exact
                path="/admin/applications"
                component={ApplicationsPage}
                can={[
                  OPERATOR_ROLE,
                  MODERATOR_ROLE,
                  SUPER_ADMIN_ROLE,
                  INTERN_ROLE,
                ]}
              />
              <ProtectedRoute
                exact
                path="/admin/applications/tasks"
                component={TasksPage}
                can={[
                  OPERATOR_ROLE,
                  MODERATOR_ROLE,
                  SUPER_ADMIN_ROLE,
                  INTERN_ROLE,
                ]}
              />
              <ProtectedRoute
                exact
                path="/admin/applications/tasks/:taskId"
                component={TaskShowPage}
                can={[
                  OPERATOR_ROLE,
                  MODERATOR_ROLE,
                  SUPER_ADMIN_ROLE,
                  INTERN_ROLE,
                ]}
              />
              <ProtectedRoute
                exact
                path="/admin/applications/:applicationId"
                component={ApplicationShowPage}
                can={[
                  OPERATOR_ROLE,
                  MODERATOR_ROLE,
                  SUPER_ADMIN_ROLE,
                  INTERN_ROLE,
                ]}
              />
              <ProtectedRoute
                exact
                path="/admin/orders"
                component={OrdersPage}
                can={[
                  OPERATOR_ROLE,
                  MODERATOR_ROLE,
                  SUPER_ADMIN_ROLE,
                  COURIER_ROLE,
                  INTERN_ROLE,
                  INTERN_OPERATOR_ROLE,
                ]}
              />
              <ProtectedRoute
                exact
                path="/admin/warehouse"
                component={WarehousePage}
                can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE, INTERN_ROLE]}
              />
              <ProtectedRoute
                exact
                path="/admin/boxes"
                component={Boxes}
                can={[
                  OPERATOR_ROLE,
                  MODERATOR_ROLE,
                  SUPER_ADMIN_ROLE,
                  COURIER_ROLE,
                ]}
              />
              <ProtectedRoute
                exact
                path="/admin/clients"
                component={ClientsPage}
                can={[
                  OPERATOR_ROLE,
                  MODERATOR_ROLE,
                  SUPER_ADMIN_ROLE,
                  INTERN_ROLE,
                  INTERN_OPERATOR_ROLE,
                ]}
              />
              <ProtectedRoute
                exact
                path="/admin/flights"
                component={FlightsPage}
                can={[OPERATOR_ROLE, MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
              />
              <ProtectedRoute
                exact
                path="/admin/delivery"
                component={Delivery}
                can={[SUPER_ADMIN_ROLE]}
              />
              <Route path="/admin/finance">
                <Switch>
                  <ProtectedRoute
                    path="/admin/finance"
                    component={Transactions}
                    exact
                    can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE, INTERN_ROLE]}
                  />
                  <ProtectedRoute
                    path="/admin/finance/invoices"
                    component={Invoices}
                    can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE, INTERN_ROLE]}
                  />
                  <ProtectedRoute
                    path="/admin/finance/cash-receipt"
                    component={CashReceipt}
                    can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE, INTERN_ROLE]}
                  />
                  <ProtectedRoute component={NotFoundPage} />
                </Switch>
              </Route>
              <Route path="/admin/marketing">
                <MarketingLayout can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}>
                  <Switch>
                    <Redirect
                      path="/admin/marketing"
                      to="/admin/marketing/newsletters"
                      exact
                    />
                    <ProtectedRoute
                      path="/admin/marketing/newsletters"
                      component={Newsletters}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                    <ProtectedRoute
                      path="/admin/marketing/loyalty-system"
                      component={Loyalty}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                    <ProtectedRoute
                      path="/admin/marketing/promo-codes"
                      component={PromoCodes}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                  </Switch>
                </MarketingLayout>
              </Route>
              <Route path="/admin/settings">
                <SettingLayout can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}>
                  <Switch>
                    <Redirect
                      path="/admin/settings"
                      to="/admin/settings/general"
                      exact
                    />
                    <ProtectedRoute
                      path="/admin/settings/general"
                      component={General}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                    <ProtectedRoute
                      path="/admin/settings/branches"
                      component={Branches}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                    <ProtectedRoute
                      path="/admin/settings/operators"
                      component={Operators}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                    <ProtectedRoute
                      path="/admin/settings/extra-service"
                      component={SettingsExtraServices}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                    <ProtectedRoute
                      path="/admin/settings/statuses"
                      component={Statuses}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                    <ProtectedRoute
                      path="/admin/settings/places"
                      component={PlacesPage}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                    <ProtectedRoute
                      path="/admin/settings/categories"
                      component={CategoriesPage}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                    <ProtectedRoute
                      path="/admin/settings/shops"
                      component={ShopsPage}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                    <ProtectedRoute
                      path="/admin/settings/pages"
                      component={SheetsPage}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                    <ProtectedRoute
                      path="/admin/settings/cashback-categories"
                      component={PromoCodeSetting}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                    <ProtectedRoute
                      path="/admin/settings/cell"
                      component={Cell}
                      can={[MODERATOR_ROLE, SUPER_ADMIN_ROLE]}
                    />
                    <ProtectedRoute component={NotFoundPage} />
                  </Switch>
                </SettingLayout>
              </Route>
              <ProtectedRoute component={NotFoundPage} />
            </Switch>
          </Suspense>
        </MainLayout>
      </Route>
      <Route component={NotFoundPage} />
    </Switch>
  );
}
