export const NEW_TASK = 1;
export const CANCELLED_TASK = 4;
export const RETURNED_TASK = 2;
export const EXECUTED_TASK = 3;

export const TASK_STATUS = {
  [NEW_TASK]: 'primary',
  [CANCELLED_TASK]: 'danger',
  [RETURNED_TASK]: 'warning',
  [EXECUTED_TASK]: 'success',
};
