import React from 'react';
import './Loader.scss';
import { Spin } from 'antd';

export const Loader = () => {
  return (
    <div className="Preloader">
      <Spin size="large" />
    </div>
  );
};
