import { CountryService } from '../../services/country.service';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_HEADER_MODAL_BUTTON = 'SET_HEADER_MODAL_BUTTON';
export const SET_SEARCHED_PAGE = 'SET_SEARCHED_PAGE';
export const SET_EDIT_STATUS_MODAL = 'SET_EDIT_STATUS_MODAL';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_PHONE_PREFIXES = 'SET_PHONE_PREFIXES';

export const toggleModal = modalName => dispatch => {
  dispatch({ type: TOGGLE_MODAL, payload: { modalName } });
};

export const fetchPhonePrefixes = params => async dispatch => {
  const res = await CountryService.getCountries(params);
  dispatch({
    type: SET_PHONE_PREFIXES,
    payload: res.data.data.map(country => country.phone_prefix),
  });
};

export const setHeaderModalButton = headerButton => dispatch => {
  dispatch({ type: SET_HEADER_MODAL_BUTTON, payload: { headerButton } });
};

//for edit modal
export const setEditStatusModal = modalName => dispatch => {
  dispatch({ type: SET_EDIT_STATUS_MODAL, payload: { modalName } });
};

export const setLoading = loading => dispatch => {
  dispatch({ type: SET_LOADING, payload: loading });
};

export const setError = error => ({ type: SET_ERROR, payload: error });

export const setSearchedPage = payload => dispatch =>
  dispatch({ type: SET_SEARCHED_PAGE, payload });
