import {
  BOX_STATUS_ARCHIVE,
  BOX_STATUS_PACKING,
  BOX_STATUS_PENDING,
  BOX_STATUS_SENT,
} from '../../constants/boxes';
import {
  CREATE_BOX,
  DELETE_BOX,
  DELETE_BOXES,
  DELETE_BOX_ORDER,
  FETCH_BOXES,
  FETCH_BOXES_BY_STATUS,
  FETCH_BOXES_FILTERS,
  FETCH_BOXES_INFO,
  FETCH_BOXES_STATUSES,
  FETCH_BOX_TYPES,
  MOVE_BOX,
  REORDER_BOX,
  REQUEST_BOXES_BY_STATUS,
  SET_BOX_QUERY_PARAM,
  SET_SHOW_TYPE,
  UPDATE_BOX,
  UPDATE_BOX_FLIGHT,
  UPDATE_KANBAN_BOX_STATUS_BY_IDS,
  UPDATE_LIST_BOX_STATUS_BY_IDS,
} from './boxes.actions';

const initialState = {
  boxes: [],
  boxesByStatus: {
    [BOX_STATUS_PACKING]: [],
    [BOX_STATUS_PENDING]: [],
    [BOX_STATUS_SENT]: [],
    [BOX_STATUS_ARCHIVE]: [],
  },
  loaders: {
    [BOX_STATUS_PACKING]: false,
    [BOX_STATUS_PENDING]: false,
    [BOX_STATUS_SENT]: false,
    [BOX_STATUS_ARCHIVE]: false,
  },
  boxTypes: [],
  statuses: [],
  boxesLength: 0,
  filters: [],
  queryParams: {
    include: 'box_orders_count,flight,status,boxType,boxOrders',
  },
  show_type: 'kanban',
  info: [],
};

export const BoxesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SHOW_TYPE:
      return {
        ...state,
        show_type: payload,
      };
    case FETCH_BOXES:
      return {
        ...state,
        boxes: payload.resetData
          ? payload.data
          : [...state.boxes, ...payload.data],
        boxesLength: payload.dataLength,
        info: payload.info,
      };
    case FETCH_BOXES_STATUSES:
      return {
        ...state,
        statuses: payload,
      };
    case FETCH_BOX_TYPES:
      return {
        ...state,
        boxTypes: payload,
      };
    case REQUEST_BOXES_BY_STATUS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [payload.key]: payload.value,
        },
      };
    case FETCH_BOXES_BY_STATUS:
      return {
        ...state,
        boxesByStatus: {
          ...state.boxesByStatus,
          [payload.status]: payload.resetData
            ? payload.data
            : [...state.boxesByStatus[payload.status], ...payload.data],
        },
      };
    case UPDATE_BOX_FLIGHT:
      return {
        ...state,
        boxesByStatus: {
          ...state.boxesByStatus,
          [BOX_STATUS_SENT]: state.boxesByStatus[BOX_STATUS_SENT].map(box => {
            if (box.id === payload.boxId) {
              return {
                ...box,
                flight: payload,
              };
            }
            return box;
          }),
        },
      };
    case MOVE_BOX:
      return {
        ...state,
        boxesByStatus: {
          ...state.boxesByStatus,
          ...payload.data,
        },
        statuses: state.statuses.map(status => {
          if (status.id === payload.sourceId) {
            return {
              ...status,
              element_count: status.element_count - 1,
            };
          } else if (status.id === payload.destinationId) {
            return {
              ...status,
              element_count: status.element_count + 1,
            };
          }
          return status;
        }),
      };
    case REORDER_BOX:
      return {
        ...state,
        boxesByStatus: {
          ...state.boxesByStatus,
          [payload.status]: payload.data,
        },
      };
    case UPDATE_KANBAN_BOX_STATUS_BY_IDS:
      const ids = payload.data.map(item => item.id);
      return {
        ...state,
        boxesByStatus: {
          ...state.boxesByStatus,
          [payload.prevStatus]: state.boxesByStatus[payload.prevStatus].filter(
            box => !ids.includes(box.id),
          ),
          [payload.nextStatus]: [
            ...payload.data,
            ...state.boxesByStatus[payload.nextStatus],
          ],
        },
        statuses: state.statuses.map(status => {
          if (status.id === payload.prevStatus) {
            return {
              ...status,
              element_count: status.element_count - payload.data.length,
            };
          } else if (status.id === payload.nextStatus) {
            return {
              ...status,
              element_count: status.element_count + payload.data.length,
            };
          }
          return status;
        }),
      };
    case UPDATE_LIST_BOX_STATUS_BY_IDS:
      return {
        ...state,
        boxes: state.boxes.map(box => {
          const updatedBox = payload.data.find(data => data.id === box.id);
          if (updatedBox) {
            return updatedBox;
          }
          return box;
        }),
      };
    case FETCH_BOXES_FILTERS:
      return { ...state, filters: payload };
    case FETCH_BOXES_INFO:
      return { ...state, info: payload };
    case SET_BOX_QUERY_PARAM:
      const isEmpty = payload && !Object.keys(payload).length;
      return {
        ...state,
        queryParams: isEmpty
          ? initialState.queryParams
          : {
              ...state.queryParams,
              ...payload,
            },
      };
    case CREATE_BOX:
      return {
        ...state,
        boxes: [payload, ...state.boxes],
        boxesByStatus: {
          ...state.boxesByStatus,
          [payload.status.id]: [
            payload,
            ...state.boxesByStatus[payload.status.id],
          ],
        },
        boxesLength: state.boxesLength + 1,
      };
    case UPDATE_BOX:
      return {
        ...state,
        boxes: state.boxes.map(box => (box.id === payload.id ? payload : box)),
        boxesByStatus: {
          ...state.boxesByStatus,
          [payload.status.id]: state.boxesByStatus[payload.status.id].map(box =>
            box.id === payload.id ? payload : box,
          ),
        },
      };
    case DELETE_BOX:
      return {
        ...state,
        boxes: state.boxes.filter(box => box.id !== payload.id),
        boxesByStatus: {
          ...state.boxesByStatus,
          [payload.oldStatus.id]: state.boxesByStatus[
            payload.oldStatus.id
          ].filter(box => box.id !== payload.id),
          [BOX_STATUS_ARCHIVE]:
            payload.oldStatus.id !== BOX_STATUS_ARCHIVE
              ? [
                  { ...payload, oldStatus: undefined },
                  ...state.boxesByStatus[BOX_STATUS_ARCHIVE],
                ]
              : state.boxesByStatus[BOX_STATUS_ARCHIVE],
        },
        boxesLength: state.boxesLength - 1,
      };
    case DELETE_BOXES:
      return {
        ...state,
        boxes: state.boxes.filter(box => !payload.includes(box.id)),
        boxesLength: state.boxesLength - 1,
      };
    case DELETE_BOX_ORDER:
      return {
        ...state,
        boxes: state.boxes.map(box => {
          if (box.id === payload.box.id) {
            return {
              ...box,
              box_orders: box.box_orders.filter(
                box_order => box_order.id !== payload.item.id,
              ),
            };
          }
          return box;
        }),
        boxesByStatus: {
          ...state.boxesByStatus,
          [payload.box.status.id]: state.boxesByStatus[
            payload.box.status.id
          ].map(box => {
            if (box.id === payload.box.id) {
              return {
                ...box,
                box_orders: box.box_orders.filter(
                  box_order => box_order.id !== payload.item.id,
                ),
              };
            }
            return box;
          }),
        },
      };
    default:
      return state;
  }
};
