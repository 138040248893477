import { StatusesService } from '../../services/statuses.service';
import { StatusTypesService } from '../../services/statusTypes.service';

export const FETCH_STATUS_TYPE = 'FETCH_STATUS_TYPE';
export const CREATE_STATUS = 'CREATE_STATUS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const DELETE_STATUS = 'DELETE_STATUS';

export const fetchStatuses = params => async dispatch => {
  const res = await StatusTypesService.getStatusTypes(params);
  dispatch({
    type: FETCH_STATUS_TYPE,
    payload: res?.data?.data,
  });
};

export const createStatus = status => async dispatch => {
  const res = await StatusesService.createStatus(status);
  dispatch({
    type: CREATE_STATUS,
    payload: res.data?.data,
  });
  return res.data.message;
};

export const updateStatus = (status, oldStatusType) => async dispatch => {
  const res = await StatusesService.updateStatus(status);
  if (res.data.data.type.id === oldStatusType) {
    dispatch({
      type: UPDATE_STATUS,
      payload: res.data.data,
    });
  } else {
    dispatch({
      type: DELETE_STATUS,
      payload: {
        id: res.data.data.id,
        type: oldStatusType,
      },
    });
    dispatch({
      type: CREATE_STATUS,
      payload: res.data?.data,
    });
  }
  return res.data.message;
};

export const deleteStatus = status => async dispatch => {
  const res = await StatusesService.deleteStatus(status.id);
  dispatch({
    type: DELETE_STATUS,
    payload: status,
  });
  return res.data.message;
};
