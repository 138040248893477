import { Button, Result } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { HAS_BRANCH } from '../../redux/auth/authAction';
import { StorageService } from '../../services/storage.service';
import { getterState } from '../../utils/getter';
import { objectToQueryString } from '../../utils/query-string';

export function ProtectedRoute({ component: Component, can = [], ...rest }) {
  const stateUser = useSelector(getterState('auth.user'));
  const stateHasBranch = useSelector(getterState('auth.hasBranch'));
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation();
  const userBranchRoute = '/admin/user-branch';

  const hasBranch = user => {
    const storeBranch = StorageService.getBranch();
    if (stateHasBranch) return true;
    const selectedBranch = user?.branches.find(
      branch => branch.id === +storeBranch,
    );
    if (selectedBranch) {
      dispatch({ type: HAS_BRANCH, payload: selectedBranch });
      return true;
    } else if (user?.branches?.length === 1) {
      dispatch({ type: HAS_BRANCH, payload: user?.branches[0] });
    } else {
      return false;
    }
  };

  const validationBranch = user => {
    if (hasBranch(user)) {
      if (rest.path === userBranchRoute) {
        history.push('/admin');
      }
    } else {
      if (rest.path !== userBranchRoute) {
        const search = objectToQueryString({
          redirectTo: history.location.pathname,
        });
        history.push({
          pathname: userBranchRoute,
          search,
        });
      }
    }
  };

  useEffect(() => {
    if (stateUser) {
      validationBranch(stateUser);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={props =>
        can.includes(stateUser?.role?.id) ? (
          <Component {...props} />
        ) : (
          <Result
            style={{ marginTop: 70 }}
            status={404}
            title={404}
            extra={
              <Button
                type="primary"
                onClick={() => props.history.push('/admin')}
              >
                {t('home')}
              </Button>
            }
          />
        )
      }
    />
  );
}
