import { OffersService } from '../../services/offers.service';

export const FETCH_OFFERS = 'FETCH_OFFERS';
export const CREATE_OFFER = 'CREATE_OFFER';
export const UPDATE_OFFER = 'UPDATE_OFFER';
export const DELETE_OFFER = 'DELETE_OFFER';

export const fetchOffers = params => async dispatch => {
  const res = await OffersService.getOffers(params);
  dispatch({
    type: FETCH_OFFERS,
    payload: res?.data?.data,
  });
};

export const fetchOrderOffers = data => async dispatch => {
  const res = await OffersService.getOrderOffers(data);
  dispatch({
    type: FETCH_OFFERS,
    payload: res?.data?.data,
  });
};

export const createOffer = offer => async dispatch => {
  const res = await OffersService.createOffers(offer);
  dispatch({
    type: CREATE_OFFER,
    payload: res.data.data,
  });
  return res.data.message;
};

export const updateOffer = offer => async dispatch => {
  const res = await OffersService.updateOffer(offer);
  dispatch({
    type: UPDATE_OFFER,
    payload: res.data.data,
  });
  return res.data.message;
};

export const deleteOffer = offer => async dispatch => {
  const res = await OffersService.deleteOffer(offer.id);
  dispatch({
    type: DELETE_OFFER,
    payload: offer.id,
  });
  return res.data.message;
};
