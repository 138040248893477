import { ExportService } from '../../services/export.service';
import { OrderService } from '../../services/order.service';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_PAGINATION = 'FETCH_ORDERS_PAGINATION';
export const FETCH_APPLICATIONS = 'FETCH_APPLICATIONS';
export const FETCH_APPLICATION = 'FETCH_APPLICATION';
export const FETCH_ORDER = 'FETCH_ORDER';
export const FETCH_ORDERS_OBJECT = 'FETCH_ORDERS_OBJECT';
export const FETCH_ORDER_FILTERS = 'FETCH_ORDER_FILTERS';
export const FETCH_ORDER_INFO = 'FETCH_ORDER_INFO';
export const CREATE_ORDER = 'CREATE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDERS_LIST = 'UPDATE_ORDERS_LIST';
export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDERS = 'DELETE_ORDERS';
export const SET_ORDER_QUERY_PARAM = 'SET_ORDER_QUERY_PARAM';
export const SET_APPLICATION_QUERY_PARAM = 'SET_APPLICATION_QUERY_PARAM';
export const SET_ORDER_MODAL_DATA = 'SET_ORDER_MODAL_DATA';
export const SET_APPLICATION_MODAL_DATA = 'SET_APPLICATION_MODAL_DATA';
export const FETCH_AVAILABLE_STATUSES = 'FETCH_AVAILABLE_STATUSES';
export const CREATE_PICKUP = 'CREATE_PICKUP';
export const CREATE_DELIVERY_SERVICE = 'CREATE_DELIVERY_SERVICE';
export const CREATE_HELP_TO_SHOP = 'CREATE_HELP_TO_SHOP';
export const SET_APPLICATION_STATUSES = 'SET_APPLICATION_STATUSES';
export const CHANGE_APPLICATION_STATUS = 'CHANGE_APPLICATION_STATUS';
export const SET_SELECTED_ROWS = 'SET_SELECTED_ROWS';

export const setSelectedRowsDelivery = selectedRows => ({
  type: SET_SELECTED_ROWS,
  payload: selectedRows,
});

export const fetchApplications = (
  params,
  resetData = true,
) => async dispatch => {
  if (resetData) {
    dispatch({
      type: FETCH_APPLICATIONS,
      payload: {
        data: [],
        resetData,
      },
    });
  }

  const res = await OrderService.getApplications(params);

  dispatch({
    type: FETCH_APPLICATIONS,
    payload: {
      data: res.data.data,
      resetData,
    },
  });

  dispatch({
    type: SET_APPLICATION_STATUSES,
    payload: res.data.statuses || [],
  });

  return res.data;
};

export const fetchApplication = (id, params) => async dispatch => {
  const res = await OrderService.getApplication(id, params);
  dispatch({ type: FETCH_APPLICATION, payload: res.data.data });
  return res.data;
};

export const fetchOrders = (params, resetData = true) => async dispatch => {
  const res = await OrderService.getOrders(params);
  dispatch({
    type: Array.isArray(res.data.data) ? FETCH_ORDERS : FETCH_ORDERS_OBJECT,
    payload: {
      data: res.data.data || [],
      dataLength: res.data.meta?.total || 0,
      resetData,
    },
  });

  dispatch({ type: FETCH_ORDER_INFO, payload: res.data.info });
};

export const fetchOrdersPaging = (
  params,
  resetData = true,
) => async dispatch => {
  const res = await OrderService.getOrders(params);

  dispatch({
    type: FETCH_ORDERS_PAGINATION,
    payload: {
      data: res.data.data || [],
      dataLength: res.data.meta?.total || 0,
      resetData,
    },
  });

  dispatch({ type: FETCH_ORDER_INFO, payload: res.data.info });
};

export const fetchOrderById = (order, params) => async dispatch => {
  if (order) {
    const res = await OrderService.getOrderById(order.id, params);
    dispatch({
      type: FETCH_ORDER,
      payload: res.data.data,
    });
    return res.data;
  }

  dispatch({
    type: FETCH_ORDER,
    payload: null,
  });
};

export const applyOrderPromoCode = (id, promo_code) => async dispatch => {
  const res = await OrderService.applyPromoCode(id, { promo_code });
  dispatch({
    type: FETCH_ORDER,
    payload: res.data.data,
  });
  return res.data;
};

export const deleteOrderPromoCode = id => async dispatch => {
  const res = await OrderService.deletePromoCode(id);
  dispatch({
    type: FETCH_ORDER,
    payload: res.data.data,
  });
  return res.data;
};

export const applyApplicationPromoCode = (id, promo_code) => async dispatch => {
  const res = await OrderService.applyAppPromoCode(id, { promo_code });
  dispatch({
    type: FETCH_APPLICATION,
    payload: res.data.data,
  });
  return res.data;
};

export const deleteApplicationPromoCode = id => async dispatch => {
  const res = await OrderService.deleteAppPromoCode(id);
  dispatch({
    type: FETCH_APPLICATION,
    payload: res.data.data,
  });
  return res.data;
};

export const fetchOrderFilters = () => async dispatch => {
  const res = await OrderService.getOrderFilters();
  dispatch({ type: FETCH_ORDER_FILTERS, payload: res.data.data });
};

export const confirmApplication = data => async dispatch => {
  const res = await OrderService.confirmApplication(data);
  dispatch({ type: FETCH_APPLICATION, payload: res.data.data });
  return res.data;
};

export const executeApplication = data => async dispatch => {
  const res = await OrderService.executeApplication(data);
  dispatch({ type: FETCH_APPLICATION, payload: res.data.data });
  return res.data;
};

export const executeHelpToShop = data => async dispatch => {
  const res = await OrderService.executeHelpToShop(data);
  dispatch({ type: FETCH_APPLICATION, payload: res.data.data });
  return res.data;
};

export const updateAppDateAndTime = data => async dispatch => {
  const res = await OrderService.updateAppDateAndTime(data);
  dispatch({ type: FETCH_APPLICATION, payload: res.data.data });
  return res.data;
};

export const updatePickupApplication = data => async dispatch => {
  const res = await OrderService.updatePickup(data);
  dispatch({ type: FETCH_APPLICATION, payload: res.data.data });
  return res.data;
};

export const updateDeliveryServiceApplication = data => async dispatch => {
  const res = await OrderService.updateDeliveryService(data);
  dispatch({ type: FETCH_APPLICATION, payload: res.data.data });
  return res.data;
};

export const updateHelpToShopApplication = data => async dispatch => {
  const res = await OrderService.updateHelpToShop(data);
  dispatch({ type: FETCH_APPLICATION, payload: res.data.data });
  return res.data;
};

export const updateInternetApplication = data => async dispatch => {
  const res = await OrderService.updateInternet(data);
  dispatch({ type: FETCH_APPLICATION, payload: res.data.data });
  return res.data;
};

export const updateSelfDeliveryApplication = data => async dispatch => {
  const res = await OrderService.updateSelfDelivery(data);
  dispatch({ type: FETCH_APPLICATION, payload: res.data.data });
  return res.data;
};

export const createOrder = order => async dispatch => {
  const res = await OrderService.createOrder(order);
  dispatch({ type: CREATE_ORDER, payload: res.data.data });
  return res.data;
};

export const cancelApplication = data => async dispatch => {
  const res = await OrderService.cancelApplication(data);
  return res.data;
};

export const createPickup = application => async dispatch => {
  const res = await OrderService.createPickup(application);
  dispatch({ type: CREATE_PICKUP, payload: res.data.data });
  return res.data;
};

export const createForwardedService = application => async dispatch => {
  const res = await OrderService.createDeliveryService(application);
  dispatch({ type: CREATE_DELIVERY_SERVICE, payload: res.data.data });
  return res.data;
};

export const createHelpToShop = application => async dispatch => {
  const res = await OrderService.createHelpToShop(application);
  dispatch({ type: CREATE_HELP_TO_SHOP, payload: res.data.data });
  return res.data;
};

export const createDraft = order => async () => {
  const res = await OrderService.addToDraft(order);
  return res.data;
};

export const updateOrder = order => async dispatch => {
  const res = await OrderService.updateOrder(order);
  dispatch({ type: UPDATE_ORDER, payload: res.data.data });
  return res.data;
};

export const deleteOrders = items => async dispatch => {
  const ids = items.map(item => item.id);
  const res = await OrderService.deleteOrders(ids);
  dispatch({ type: UPDATE_ORDERS_LIST, payload: res.data.data });
  return res.data.message;
};

export const cancelTasks = items => async dispatch => {
  const ids = items.map(item => item.id);
  const res = await OrderService.cancelTasks(ids);
  dispatch({ type: UPDATE_ORDERS_LIST, payload: [] });
  return res.data.message;
};

export const setOrderModal = payload => dispatch => {
  dispatch({ type: SET_ORDER_MODAL_DATA, payload });
};

export const setAppModal = payload => dispatch => {
  dispatch({ type: SET_APPLICATION_MODAL_DATA, payload });
};

export const fetchAvailableStatuses = () => async dispatch => {
  const res = await OrderService.getAvailableStatuses();
  dispatch({ type: FETCH_AVAILABLE_STATUSES, payload: res.data.data });
};

export const updateOrderStatuses = (orders, status) => async dispatch => {
  const res = await OrderService.updateOrderStatuses(
    orders.map(order => order.id),
    status.id,
  );
  dispatch({ type: UPDATE_ORDERS_LIST, payload: res.data.data });
  return res.data;
};

export const combineOrders = (
  sourceOrderId,
  combinedOrders,
  params,
) => async () => {
  const combinedOrdersIds = combinedOrders.map(order => order.id);
  const res = await OrderService.combineOrders(
    sourceOrderId,
    combinedOrdersIds,
    params,
  );
  // dispatch({ type: UPDATE_ORDER, payload: res.data.data });
  // dispatch({ type: DELETE_ORDERS, payload: combinedOrdersIds });
  return res.data;
};

export const addOrdersToBox = (boxId, orders) => async dispatch => {
  const res = await OrderService.addToBox(
    boxId,
    orders.map(order => order.id),
  );
  dispatch({ type: UPDATE_ORDERS_LIST, payload: res.data.data });
  return res.data;
};

export const printOrder = (payload, data) => async () => {
  const res = await ExportService.getExportedDocUrl(payload, data);

  return res.data;
};
