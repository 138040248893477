import { UserService } from '../../services/users.service';

export const FETCH_ROLES = 'FETCH_ROLES';

export const fetchRoles = (params) => async (dispatch, getState) => {
  const state = getState();
  if (state.roles.roles.length) return;
  const res = await UserService.getRoles(params);

  dispatch({ type: FETCH_ROLES, payload: res.data.data });
};
