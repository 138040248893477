import { http } from './api.service';

export const ShopService = {
  getShops(params) {
    return http.get('/shops', {
      params,
    });
  },
  createShop(shop) {
    return http.post('/shops', shop);
  },
  updateShop(shop) {
    return http.post(`/shops/${shop.get('id')}`, shop);
  },
  deleteShop(id) {
    return http.delete(`/shops/${id}`);
  },
  //   fetchCategoryTypes() {
  //     return http.get('/categories/types');
  //   },
};
