import React from 'react';

export const SVGIcon = ({ className, iconPath, svgClassName, ...rest }) => {
  return (
    <i className={`MainIcon ${className || ''} ${iconPath}`} {...rest}>
      <svg className={svgClassName}>
        <use href={`#${iconPath}`} xlinkHref={`#${iconPath}`}></use>
      </svg>
      {rest.children}
    </i>
  );
};
