import { http } from './api.service';

export const CellService = {
  getCells(params) {
    return http.get('/storage', {
      params,
    });
  },
  createCell(cell) {
    if (cell.client_id) {
      var str = cell.client_id;
      var strCharArr;
      [...strCharArr] = str;
      var arr = strCharArr.reduce(
        (acc, cv) => {
          if (cv == ' ') acc.push('');
          else acc[acc.length - 1] += cv;
          return acc;
        },
        [''],
      );
      const newCell = { ...cell, client_id: parseInt(arr[0]) };
      return http.post('/storage', newCell);
    } else {
      const newCell = { ...cell, client_id: null };
      return http.post('/storage', newCell);
    }
  },
  updateCell(cell) {
    if (cell.client_id) {
      var str = cell.client_id;
      var strCharArr;
      [...strCharArr] = str;
      var arr = strCharArr.reduce(
        (acc, cv) => {
          if (cv == ' ') acc.push('');
          else acc[acc.length - 1] += cv;
          return acc;
        },
        [''],
      );
      const newCell = { ...cell, client_id: parseInt(arr[0]) };
      return http.put(`/storage/${cell.id}`, newCell);
    } else {
      const newCell = { ...cell, client_id: null };
      return http.put(`/storage/${cell.id}`, newCell);
    }
  },
  deleteCell(id) {
    return http.delete(`/storage/${id}`);
  },
  getOrdersForCell(id) {
    return http.get(`/storage/${id}/orders`);
  },
};
