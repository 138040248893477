import { http } from './api.service';

export const BatchesService = {
  getBatches(params) {
    return http.get('/packages', {
      params,
    });
  },
  createBatch(data) {
    return http.post('/packages', data);
  },
  updateBatch(data) {
    return http.put(`/packages/${data.id}`, data);
  },
  deleteBatchs(ids) {
    return http.delete(`/packages/${ids}`);
  },
  getBatchFilters() {
    return http.get('/packages/filter-list');
  },
  payments(id, param) {
    return http.get(`/packages/${id}/payments`, { param });
  },
};
