import { UserService } from '../../services/users.service';
import { setError } from '../common/common.actions';

export const FETCH_OPERATORS = 'FETCH_OPERATORS';
export const PUSH_OPERATORS = 'PUSH_OPERATORS';
export const CREATE_OPERATOR = 'CREATE_OPERATOR';
export const UPDATE_OPERATOR = 'UPDATE_OPERATOR';
export const DELETE_OPERATOR = 'DELETE_OPERATOR';
export const SET_OPERATORS_LOADER = 'SET_OPERATORS_LOADER';

export const fetchOperators = (params, resetData = true) => async (
  dispatch,
) => {
  dispatch({ type: SET_OPERATORS_LOADER, payload: true });
  try {
    const res = await UserService.getUsers(params);

    dispatch({
      type: !resetData ? PUSH_OPERATORS : FETCH_OPERATORS,
      payload: {
        data: res.data.data,
        dataLength: res.data.meta.total,
      },
    });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch({ type: SET_OPERATORS_LOADER, payload: false });
  }
};

export const createOperator = (operator) => async (dispatch) => {
  const res = await UserService.createUser(operator);

  await dispatch({ type: CREATE_OPERATOR, payload: res.data.data });
  return res.data.message;
};

export const updateOperator = (operator) => async (dispatch) => {
  const res = await UserService.updateUser(operator);

  await dispatch({ type: UPDATE_OPERATOR, payload: res.data.data });
  return res.data.message;
};

export const deleteOperator = (operator) => async (dispatch) => {
  const res = await UserService.deleteUser(operator.id);

  await dispatch({ type: DELETE_OPERATOR, payload: operator.id });
  return res.data.message;
};
