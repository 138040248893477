import {
  SET_ERROR,
  SET_HEADER_MODAL_BUTTON,
  SET_LOADING,
  SET_PHONE_PREFIXES,
  SET_SEARCHED_PAGE,
  TOGGLE_MODAL,
} from './common.actions';

const initialState = {
  modals: {},
  headerButton: {
    name: '',
    modalName: '',
  },
  searchedPage: '',
  loading: false,
  error: null,
  phonePrefixes: [],
};

export const CommonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_MODAL:
      state.modals[payload.modalName] = !state.modals[payload.modalName];
      return { ...state };
    case SET_PHONE_PREFIXES:
      return {
        ...state,
        phonePrefixes: payload,
      };
    case SET_HEADER_MODAL_BUTTON:
      return { ...state, headerButton: payload.headerButton };
    case SET_LOADING:
      return { ...state, loading: payload };
    case SET_ERROR:
      return { ...state, error: payload };
    case SET_SEARCHED_PAGE:
      return { ...state, searchedPage: payload };
    default:
      return state;
  }
};
