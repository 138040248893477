import { useTranslation } from 'react-i18next';

const isNotNull = value => {
  return value !== null && typeof value !== 'undefined';
};

export const TextComponent = ({ value, separator = ', ', oneLine, noData }) => {
  const [t] = useTranslation();
  if (!isNotNull(noData)) {
    noData = t('no_data');
  }
  if (Array.isArray(value)) {
    const strings = value.filter(val => isNotNull(val));
    return strings.length > 0 ? strings.join(separator) : noData;
  }
  return isNotNull(value) ? value : noData;
};
