export const OPERATOR_STATUS_DEACTIVE = 0;
export const OPERATOR_STATUS_ACTIVE = 1;
export const OPERATOR_STATUS_DELETED = 2;

export const OPERATOR_STATUS_CLASSNAME = {
  [OPERATOR_STATUS_DEACTIVE]: 'secondary',
  [OPERATOR_STATUS_ACTIVE]: 'success',
  [OPERATOR_STATUS_DELETED]: 'danger',
};

export const OPERATOR_STATUS_TEXT = {
  [OPERATOR_STATUS_DEACTIVE]: 'inactive',
  [OPERATOR_STATUS_ACTIVE]: 'active',
  [OPERATOR_STATUS_DELETED]: 'deleted',
};
