import { DeliveryService } from '../../services/delivery.service';

export const FETCH_DELIVERY = 'FETCH_DELIVERY';
export const FETCH_DELIVERY_FILTERS = 'FETCH_DELIVERY_FILTERS';
export const FETCH_DELIVERY_INFO = 'FETCH_DELIVERY_INFO';
export const SET_DELIVERY_QUERY_PARAM = 'SET_DELIVERY_QUERY_PARAM';

export const fetchDeliverys = (params, resetData = true) => async dispatch => {
  const res = await DeliveryService.getDeliverys(params);
  dispatch({
    type: FETCH_DELIVERY,
    payload: {
      data: res.data?.data || [],
      dataLength: res.data.meta?.total || 0,
      resetData,
    },
  });
  dispatch({ type: FETCH_DELIVERY_INFO, payload: res.data.info });
};

export const fetchDeliveryFilters = () => async dispatch => {
  const res = await DeliveryService.getDeliveryFilters();

  dispatch({ type: FETCH_DELIVERY_FILTERS, payload: res.data.data });
};
