import {
  CREATE_BATCH,
  DELETE_BATCHES,
  FETCH_BATCHES,
  FETCH_BATCH_FILTERS,
  UPDATE_BATCH,
  SET_BATCH_QUERY_PARAM,
  FETCH_BATCH_INFO,
  BATCH_PAYMENT,
} from './warehouses.actions';

const initialState = {
  batches: [],
  filters: [],
  info: [],
  queryParams: {},
  batchesLength: 0,
  paymentMethods: [],
};

export const WarehouseReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BATCHES:
      return {
        ...state,
        batches: payload.resetData
          ? payload.data
          : [...state.batches, ...payload.data],
        batchesLength: payload.dataLength,
      };
    case BATCH_PAYMENT:
      return {
        ...state,
        batches: state.batches.map(batch => {
          if (batch.id === payload.id) {
            return {
              ...batch,
              payments: batch.payments
                ? [...batch.payments, payload.data]
                : [payload.data],
            };
          }
          return batch;
        }),
      };
    case FETCH_BATCH_FILTERS:
      return { ...state, filters: payload };
    case FETCH_BATCH_INFO:
      return { ...state, info: payload };
    case SET_BATCH_QUERY_PARAM:
      const isEmpty = payload && !Object.keys(payload).length;
      return {
        ...state,
        queryParams: isEmpty
          ? {}
          : {
              ...state.queryParams,
              ...payload,
            },
      };
    case CREATE_BATCH:
      return {
        ...state,
        batches: [payload, ...state.batches],
        batchesLength: state.batchesLength + 1,
      };
    case UPDATE_BATCH:
      return {
        ...state,
        batches: state.batches.map(batch =>
          batch.id === payload.id ? payload : batch,
        ),
      };
    case DELETE_BATCHES:
      return {
        ...state,
        batches: state.batches.filter(batch => !payload.includes(batch.id)),
        batchesLength: state.batchesLength - 1,
      };
    default:
      return state;
  }
};
