import { http } from './api.service';

export const MarketingService = {
  fetchPromoCodes(params) {
    return http.get('/promocodes', { params });
  },
  fetchPromoCodeCategories(params) {
    return http.get('/cashback/categories', { params });
  },
  fetchPromoCodeTypes(params) {
    return http.get('/promocode-types', { params });
  },
  createPromoCode(data, params) {
    return http.post('/promocodes', data, { params });
  },
  updatePromoCode(data, params) {
    return http.put('/promocodes/' + data.id, data, { params });
  },
  createPromoCodeCategory(data, params) {
    return http.post('/cashback/categories', data, { params });
  },
  updatePromoCodeCategory(data, params) {
    return http.put('/cashback/categories/' + data.id, data, { params });
  },
  deletePromoCode(ids, params) {
    return http.delete('/promocodes/' + ids, { params });
  },
  getUsed(id, params) {
    return http.get(`/promocodes/${id}/used-promo-codes`, { params });
  },
  fetchNewsletters(params) {
    return http.get('/newsletters', { params });
  },
  createNewsletter(data, params) {
    return http.post('/newsletters', data, { params });
  },
  updateNewsletter(id, data, params) {
    return http.post('/newsletters/' + id, data, { params });
  },
  deleteNewsletter(ids, params) {
    return http.delete('/newsletters/' + ids, { params });
  },
  startNewsletter(id) {
    return http.post('/newsletters/send/' + id);
  },
  stopNewsletter(id) {
    return http.post('/newsletters/stop/' + id);
  },
  deleteNewsletterImage(id) {
    return http.delete('/newsletters/delete-photo/' + id);
  },
  updateNewsletterLecture(data) {
    return http.put('/newsletters/update-auditorium/' + data.id, data);
  },
  getCashbackList(params) {
    return http.get('/cashback/used', { params });
  },
};
