import { PaymentService } from '../../services/payment.service';

export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_TRANSACTION_FILTERS = 'FETCH_TRANSACTION_FILTERS';
export const FETCH_TRANSACTION_INFO = 'FETCH_TRANSACTION_INFO';
export const SET_TRANSACTION_QUERY_PARAM = 'SET_TRANSACTION_QUERY_PARAM';
export const FETCH_INVOICES_FILTERS = 'FETCH_INVOICES_FILTERS';
export const FETCH_INVOICES_INFO = 'FETCH_INVOICES_INFO';
export const SET_INVOICES_QUERY_PARAM = 'SET_INVOICES_QUERY_PARAM';
export const FETCH_CASHRECEIPT = 'FETCH_CASHRECEIPT';
export const FETCH_CASHRECEIPT_INFO = 'FETCH_CASHRECEIPT_INFO';
export const FETCH_CASHRECEIPT_FILTERS = 'FETCH_CASHRECEIPT_FILTERS';
export const SET_CASHRECEIPT_QUERY_PARAM = 'SET_CASHRECEIPT_QUERY_PARAM';

export const fetchPaymentMethods = params => async dispatch => {
  const res = await PaymentService.getPaymentMethods(params);
  dispatch({ type: FETCH_PAYMENT_METHODS, payload: res.data.data });
};

export const fetchTransactions = (
  params,
  resetData = true,
) => async dispatch => {
  const res = await PaymentService.fetchTransactions(params);
  dispatch({
    type: FETCH_TRANSACTIONS,
    payload: { data: res.data.data, resetData },
  });
  dispatch({ type: FETCH_TRANSACTION_INFO, payload: res.data.info });
};

export const fetchInvoices = (params, resetData = true) => async dispatch => {
  const res = await PaymentService.fetchInvoices(params);
  dispatch({
    type: FETCH_INVOICES,
    payload: { data: res.data.data, resetData },
  });
  dispatch({ type: FETCH_INVOICES_INFO, payload: res.data.info });
};

export const fetchTransactionFilters = () => async dispatch => {
  const res = await PaymentService.fetchTransactionFilters();
  dispatch({ type: FETCH_TRANSACTION_FILTERS, payload: res.data.data });
};

export const fetchInvoiceFilters = () => async dispatch => {
  const res = await PaymentService.fetchInvoiceFilters();
  dispatch({ type: FETCH_INVOICES_FILTERS, payload: res.data.data });
};

export const createPayment = data => async dispatch => {
  const res = await PaymentService.createPayment(data);
  console.log('res', res);
  return res.data;
};

export const fetchCashReceipt = (
  params,
  resetData = true,
) => async dispatch => {
  const res = await PaymentService.fetchCashReceipt(params);

  dispatch({
    type: FETCH_CASHRECEIPT,
    payload: { data: res.data.data, resetData },
  });
  dispatch({ type: FETCH_CASHRECEIPT_INFO, payload: res.data.info });
};

export const fetchCashReceiptFilters = () => async dispatch => {
  const res = await PaymentService.fetchCashReceiptFilters();
  dispatch({ type: FETCH_CASHRECEIPT_FILTERS, payload: res.data.data });
};
