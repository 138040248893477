import { FETCH_ROLES } from './roles.actions';

const initialState = {
  roles: [],
  rolesLength: 0,
};

export const RolesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ROLES:
      return {
        ...state,
        roles: payload,
      };
    default:
      return state;
  }
};
