import { CREATE_BRANCH, DELETE_BRANCH, FETCH_BRANCHES, UPDATE_BRANCH } from './branches.actions';

const initialState = {
  branches: [],
};

export const BranchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BRANCHES:
      return { ...state, branches: payload };
    case CREATE_BRANCH:
      return { ...state, branches: [...state.branches, payload] };
    case UPDATE_BRANCH:
      return {
        ...state,
        branches: state.branches.map((branch) => (branch.id === payload.id ? payload : branch)),
      };
    case DELETE_BRANCH:
      return { ...state, branches: state.branches.filter((branch) => branch.id !== payload) };
    default:
      return state;
  }
};
