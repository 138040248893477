import { BranchesService } from '../../services/branches.service';
import { setError, SET_LOADING } from '../common/common.actions';

export const FETCH_BRANCHES = 'FETCH_BRANCHES';
export const CREATE_BRANCH = 'CREATE_BRANCH';
export const UPDATE_BRANCH = 'UPDATE_BRANCH';
export const DELETE_BRANCH = 'DELETE_BRANCH';

export const fetchBranches = (params) => async (dispatch, getState) => {
  const state = getState();
  if (state.branches.branches.length) return;
  dispatch({ type: SET_LOADING, payload: true });
  try {
    const res = await BranchesService.getBranches(params);
    dispatch({ type: FETCH_BRANCHES, payload: res?.data?.data });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch({ type: SET_LOADING, payload: false });
  }
};

export const createBranch = (branch) => async (dispatch) => {
  const res = await BranchesService.createBranch(branch);
  dispatch({ type: CREATE_BRANCH, payload: res?.data?.data });
  return res.data.message;
};

export const updateBranch = (branch) => async (dispatch) => {
  const res = await BranchesService.updateBranch(branch);
  dispatch({ type: UPDATE_BRANCH, payload: res?.data?.data });
  return res.data.message;
};

export const deleteBranch = ({ id }) => async (dispatch) => {
  await BranchesService.deleteBranch(id);
  dispatch({ type: DELETE_BRANCH, payload: id });
};
