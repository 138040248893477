import { http } from './api.service';

export const BranchesService = {
  getBranches(params) {
    return http.get('/branches', {
      params,
    });
  },
  createBranch(branch, params) {
    return http.post('/branches', branch, {
      params,
    });
  },
  updateBranch(branch, params) {
    return http.put(`/branches/${branch.id}`, branch, {
      params,
    });
  },
  deleteBranch(id) {
    return http.delete(`/branches/${id}`);
  },
};
