export const BOX_STATUS_TYPE = 5;
export const BOX_STATUS_PACKING = 20;
export const BOX_STATUS_PENDING = 21;
export const BOX_STATUS_SENT = 22;
export const BOX_STATUS_ARCHIVE = 23;

export const BOX_STATUS_CLASS_NAMES = {
  [BOX_STATUS_PACKING]: 'secondary-light',
  [BOX_STATUS_PENDING]: 'primary-light',
  [BOX_STATUS_SENT]: 'success-light',
  [BOX_STATUS_ARCHIVE]: 'violet-light',
};
