import { StorageService } from '../../services/storage.service';
import {
  BEGIN_AUTH,
  SUCCESS_AUTH,
  FAIL_AUTH,
  AUTH_LOGOUT,
  GET_SETTINGS,
  UPDATE_SITE,
  FETCH_ME,
  HAS_BRANCH,
} from './authAction';

const initialState = {
  loading: false,
  error: false,
  loggedIn: !!StorageService.getToken(),
  site: null,
  user: null,
  hasBranch: null,
  commissionPurchasePercent: 5,
  isUSA: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case BEGIN_AUTH:
      return {
        ...state,
        loading: true,
        error: false,
        loggedIn: false,
      };
    case HAS_BRANCH:
      return {
        ...state,
        hasBranch: payload,
        isUSA: +payload?.address?.country?.phone_prefix === 1,
      };
    case SUCCESS_AUTH:
      return {
        ...state,
        loading: false,
        error: false,
        loggedIn: true,
      };
    case FAIL_AUTH:
      return {
        ...state,
        loading: false,
        error: true,
        loggedIn: false,
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        loggedIn: false,
        hasBranch: null,
        site: null,
        user: null,
        loading: false,
      };
    case FETCH_ME:
      return {
        ...state,
        user: payload,
      };
    case GET_SETTINGS:
      return {
        ...state,
        site: payload,
      };
    case UPDATE_SITE:
      return {
        ...state,
        site: payload,
      };
    default:
      return state;
  }
};
