import axios from 'axios';
import { config } from '../config';

const http = axios.create({
  baseURL: config.API_ROOT,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
  timeout: 720000,
});

http.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  const branch = localStorage.getItem('branch');
  if (branch) config.headers['X-SESSION-ID'] = branch;
  config.headers.Authorization = `Bearer ${token}`;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  config.cancelToken = source.token;

  if (!window.navigator.onLine) {
    const error = new Error('No internet connection');
    error.offline = true;
    throw error;
  }

  return config;
});

http.interceptors.response.use(
  response =>
    new Promise((resolve, _) => {
      resolve(response);
    }),
  error => {
    return Promise.reject(error);
  },
);

export { http };
